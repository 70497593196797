import { settings } from '@rhim/design';
import { CircleXIcon as CircleXSVG } from '@rhim/icons/24';
import { ProgressBar } from '@rhim/react';
import formatFilesize from 'filesize';
import * as React from 'react';
import styled from 'styled-components';

const CircleXIcon = <CircleXSVG fill={settings.colors.Primary.Blue_9} />;

interface Props {
  fileName: string;
  fileSize: number;
  progress: number;
  onCancel: React.UIEventHandler<HTMLDivElement>;
}

export const Uploading: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ fileName, fileSize, progress, onCancel }) => {
  const [total, uploaded] = React.useMemo(() => {
    return [fileSize, (fileSize * progress) / 100].map((size) => formatFilesize(size));
  }, [fileSize, progress]);

  return (
    <Wrapper>
      <Centerer>
        <PercentContainer>
          <Percent>{progress.toFixed(0)}%</Percent>
        </PercentContainer>
      </Centerer>
      <ProgressContainer>
        <Filename>{fileName}</Filename>
        <ProgressBar
          type="line"
          percent={progress}
          showInfo={false}
          strokeWidth={4}
          strokeColor={settings.colors.Operational.State_Blue_2}
          trailColor={settings.colors.Primary.Grey_3}
          style={{ fontSize: '8px' }}
        />
        <div>
          <Filesize>
            {uploaded}/{total}
          </Filesize>
        </div>
      </ProgressContainer>
      <Centerer>
        <Cancel onClick={onCancel} role="button">
          <Target>{CircleXIcon}</Target>
        </Cancel>
      </Centerer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${settings.colors.Primary.Grey_4};
`;

const Centerer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PercentContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Percent = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-size: ${settings.typography.FontSize.Large};
  font-family: ${settings.typography.FontFamily.Bold};
`;

const ProgressContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-grow: 1;
`;

const Filename = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

const Filesize = styled.span`
  color: ${settings.colors.Primary.Grey_4};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  text-transform: uppercase;
`;

const Cancel = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Target = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
