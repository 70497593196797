import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { ConfigProvider, Switch as AntdSwitch, SwitchProps } from 'antd';
import React, { useState } from 'react';
import { useId } from 'react-aria';
import styled from 'styled-components';

type Theme = 'light' | 'dark';

interface Props extends SwitchProps {
  /**
   * Switcher label
   */
  label?: string | JSX.Element;
  toggleBefore?: boolean;
  mode?: Theme;
}

const colorTheme = {
  light: {
    labelColor: settings.colors.Primary.Grey_8,
    background: settings.colors.Primary.Grey_4,
    backgroundChecked: settings.colors.Primary.Blue_9,
    handleBg: settings.colors.Primary.Grey_1,
    handleBgChecked: settings.colors.Primary.Grey_1,
  },
  dark: {
    labelColor: settings.colors.Primary.Grey_4,
    background: settings.colors.Primary.Grey_7,
    backgroundChecked: settings.colors.Primary.Grey_1,
    handleBg: settings.colors.Primary.Grey_1,
    handleBgChecked: settings.colors.Primary.Grey_9,
  },
} as const;

const Switch: React.ChildlessComponent<Props> = ({ checked, toggleBefore = true, label, className, mode = 'light', onChange, size = 'default', ...rest }) => {
  const id = useId();
  // internal state is required for handleBg design token
  const [isChecked, setIsChecked] = useState<boolean>(checked === true);

  const { labelColor, background, backgroundChecked, handleBg, handleBgChecked } = colorTheme[mode];

  const antdSwitch = (
    <AntdSwitch
      id={id}
      checked={checked}
      onChange={(_checked: boolean, _event: React.MouseEvent<HTMLButtonElement>) => {
        onChange?.(_checked, _event);
        setIsChecked((current) => !current);
      }}
      size={size}
      {...rest}
    />
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Switch: {
            handleBg: isChecked ? handleBgChecked : handleBg,
            colorPrimary: backgroundChecked,
            colorPrimaryHover: backgroundChecked,
            trackHeight: 24,
            trackHeightSM: 16,
            trackMinWidth: 48,
            trackMinWidthSM: 32,
            trackPadding: size === 'default' ? 3 : 2,
            // background color
            colorTextQuaternary: background,
            // background hover color
            colorTextTertiary: background,
          },
        },
      }}
    >
      <SWrapper className={className}>
        {toggleBefore && antdSwitch}
        {isDefined(label) && (
          <SSwitchLabel htmlFor={id} left={toggleBefore} color={labelColor}>
            {label}
          </SSwitchLabel>
        )}
        {!toggleBefore && antdSwitch}
      </SWrapper>
    </ConfigProvider>
  );
};

const SWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SSwitchLabel = styled.label<{ left?: boolean; color: string }>`
  padding-left: ${(props) => (props.left === true ? settings.Spacing.Spacing_100 : 'inherit')};
  padding-right: ${(props) => (props.left !== true ? settings.Spacing.Spacing_100 : 'inherit')};
  white-space: nowrap;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
`;

Switch.whyDidYouRender = true;
export default React.memo(Switch);
