export enum ChosenVesselType {
  BOF = 'bof',
  RHDegasser = 'rh',
  Ladle = 'ladle',
  EAF = 'eaf',
}

export enum MeasurementFileType {
  FilteredCSV = 'ProcessMetrixFiltered',
  VertexCSV = 'ProcessMetrixVertex',
  SummaryCSV = 'ProcessMetrixSummary',
  MPM_SPM = 'ProcessMetrixRaw', // Note: SPM and MPM resemble the same file type, they just have different extensions (this extension is configured in LCS based on the converter type)
  ZIP = 'ProcessMetrixZip',
  PostMortem = 'PostMortem',
  STL = 'STL',
}

export enum ProcessedDataFileType {
  ProcessedCSV = 'ProcessData',
}

export enum PointCloudScanFileType {
  PTS = 'PTS',
}

/**
 * Note: you may be tempted to use a more sophisticated MIME type such as
 * `application/zip` or `application/x-zip-compressed` instead of the extension.
 *
 * Don't. Stick to file extensions. They work across browsers.
 *
 * For example, a `zip` file is `application/zip` on macOS but `application/x-zip-compressed` on Windows.
 */
export enum MIMEType {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
  ZIP = '.zip',
  MPM = '.mpm',
  SPM = '.spm',
  STL = '.stl',
  PTS = '.pts',
  JSON = '.json',
  GLB = '.glb',
  GLTF = '.gltf',
  E57 = '.e57',
}
