import { RcFile } from '@rhim/react';
import type { RHIMMeasurementServiceV1ModelsUploadPtsResponseDto } from '@rhim/rest/measurementService';
import { assert, isDefined, isDictionaryLike, isString, MeasurementFileType, MIMEType } from '@rhim/utils';
import { AxiosError, CancelToken } from 'axios';
import { FlateError, gzip } from 'fflate';

import { API } from '../../api/measurementService';
import Api, { ErrorCode, SuccessResponseType } from '../../api/Upload';
import { environment } from '../../environments/environment';
import { IFilePointCloudUploadMetadata, IFileUploadMetadata, ILesFileUploadMetadata, MetadataType } from './types';
/**
 * Gets specific file type.
 */
const getSpecificFileType = (fileName: string, isPostMortem: boolean) => {
  const csv = isPostMortem ? MeasurementFileType.PostMortem : MeasurementFileType.FilteredCSV;
  return fileName.endsWith(MIMEType.ZIP) ? MeasurementFileType.ZIP : csv;
};

const appendDefined = (formData: FormData, metadata: Array<[key: string, value: string | undefined, encode?: boolean]>) => {
  metadata.forEach(([key, value, encode]) => {
    isDefined(value) && value !== '' && formData.append(key, encode === true ? encodeURIComponent(value) : value);
  });
};

const isErrorResponse = (candidate: unknown): candidate is APO.ErrorResponse => {
  return (
    isDictionaryLike(candidate) &&
    typeof candidate['status'] === 'number' && // eslint-disable-line dot-notation
    typeof candidate['detail'] === 'string' && // eslint-disable-line dot-notation
    typeof candidate['title'] === 'string' && // eslint-disable-line dot-notation
    typeof candidate['type'] === 'string' // eslint-disable-line dot-notation
  );
};

const isErrorResponse401 = (candidate: unknown): candidate is APO.ErrorResponse401 => {
  // eslint-disable-next-line dot-notation
  return isDictionaryLike(candidate) && typeof candidate['statusCode'] === 'number' && typeof candidate['message'] === 'string';
};

function getFormData(metadata: IFileUploadMetadata | ILesFileUploadMetadata | IFilePointCloudUploadMetadata, fileName: string): FormData {
  const formData = new FormData();
  switch (metadata.metadataType) {
    case MetadataType.Default: {
      formData.append('VesselType', metadata.vesselType ?? '');
      formData.append('FileType', isDefined(metadata.isPostMortem) ? getSpecificFileType(fileName, metadata.isPostMortem) : metadata.fileType);
      formData.append('campaign', metadata.campaignName ?? '');
      formData.append('vesselId', metadata.vesselId ?? '');
      formData.append('heat', isDefined(metadata.heatNumber) ? metadata.heatNumber.toString() : '');
      formData.append('measurementTaken', metadata.measurementTaken ?? '');
      formData.append('vesselState', metadata.vesselState ?? '');
      break;
    }
    case MetadataType.LesPointCloud: {
      appendDefined(formData, [
        ['VesselId', metadata.vesselId],
        ['RefractoryCondition', metadata.refractoryCondition],
        ['MeasurementTimestamp', metadata.measurementTaken],
        ['EmailOfScanResponsible', metadata.emailResponsible],
        ['ScanResolution', metadata.scanResolution],
        ['Comment', metadata.comment, true],
        ['ReferencePointOpeningX', metadata.referencePointOpeningX?.toString()],
        ['ReferencePointOpeningY', metadata.referencePointOpeningY?.toString()],
        ['ReferencePointOpeningZ', metadata.referencePointOpeningZ?.toString()],
      ]);
      break;
    }
    case MetadataType.PointCloud: {
      if (isDefined(metadata.referenceMeasurementId)) {
        formData.append('referenceMeasurementId', metadata.referenceMeasurementId);
      }

      formData.append('vesselShortName', metadata.vesselShortName);
      formData.append('VesselType', metadata.vesselType);
      formData.append('campaign', metadata.campaign.toString());
      formData.append('measurementTaken', metadata.measurementTaken);
      formData.append('heat', metadata.heat.toString());
      formData.append('vesselLining', metadata.vesselLining);
      formData.append('liningCondition', metadata.liningCondition);
      formData.append('hotMeasurement', String(metadata.hotMeasurement));
      formData.append('viewOnly', String(metadata.isUploadModeViewOnly));
      if (isDefined(metadata.liningMaintenanceType)) {
        formData.append('liningMaintenanceType', metadata.liningMaintenanceType);
      }
      if (isDefined(metadata.notes)) {
        formData.append('notes', metadata.notes);
      }
      break;
    }
  }
  return formData;
}

interface UploadError {
  title: string;
  message?: string;
}

/**
 * Compress the file parts with gzip to save bandwidth by approx. 75% for PTS files
 */
async function compressGzip(chunk: Blob): Promise<Blob> {
  const data = await chunk.arrayBuffer();

  // use the asynchronous gzip version of fflate instead of the sync one since it is up to 3x faster
  // since that one is using a callback, we wrap it into a promise to allow working with async/await
  // note: level 1 is used since text doesn't benefit that much from higher compression but drastically improves compression time
  return new Promise<Blob>((resolve, reject) => {
    gzip(new Uint8Array(data), { level: 1, consume: true }, (err: FlateError | null, compressed: Uint8Array) => {
      if (err) {
        return reject(err);
      }

      const blob = new Blob([compressed]);
      log.info(
        `Original File size: ${chunk.size} Bytes (${(chunk.size / 1024).toFixed(1)} kB). Compressed blob size: ${blob.size} Bytes (${(blob.size / 1024).toFixed(
          1
        )} kB)`
      );

      return resolve(blob);
    });
  });
}

export const uploadFile = async (
  file: RcFile,
  metadata: IFileUploadMetadata | ILesFileUploadMetadata | IFilePointCloudUploadMetadata,
  cancelToken: CancelToken,
  onProgress?: (percent: number) => void,
  onSuccess?: (data: SuccessResponseType) => void,
  onError?: (errorCode: ErrorCode, error?: UploadError) => void
) => {
  const formData = getFormData(metadata, file.name);
  const addTimestampHeader = () => ({ 'X-Request-Utc': Date.now().toString() });
  const isLesPointCloud = metadata.metadataType === MetadataType.LesPointCloud;

  if ([MetadataType.PointCloud, MetadataType.LesPointCloud].includes(metadata.metadataType)) {
    // Chunked PTS upload
    /**
     * Note: The compression takes a lot of CPU computation time in the build pipeline on the Azure CI agents.
     * Therefore, we're not going to use compression when running E2E tests.
     * Temporary solution until #271424 PBI is finished
     */
    const hasGzipCompression = environment.isUnderTest === false && metadata.metadataType === MetadataType.PointCloud;
    const initialChunkSize = 1024;
    const megaByte = 1024 * 1024; // 1 MB in Bytes
    const chunkSize = 10 * megaByte; // use a chunk size of 10 MB by default

    // if file is smaller than the initial chunk size, let's use only 1 chunk
    // otherwise we use an initial chunk and (chunkSize - 1) equally sized chunks of about 10MB the rest of the file
    const chunkCount = file.size <= initialChunkSize ? 1 : Math.ceil((file.size - initialChunkSize) / chunkSize);
    formData.append('fileName', file.name);
    formData.append('fileSize', `${file.size}`);

    // denote if the file chunks are compressed with gzip
    if (hasGzipCompression) {
      formData.append('fileCompression', 'gzip');
    }

    const initialSlice = file.slice(0, initialChunkSize);
    const initialFile = hasGzipCompression ? await compressGzip(initialSlice) : initialSlice;

    formData.append('chunkSize', initialSlice.size.toString());
    formData.append('file', initialFile, file.name);

    let chunkStart = initialChunkSize;
    let lastResponse: RHIMMeasurementServiceV1ModelsUploadPtsResponseDto | SuccessResponseType | undefined;

    // augment error handling with custom business logic before propagating it to provided error handler
    const onErrorOrig = onError;
    onError = (errorCode: ErrorCode, error?: UploadError) => {
      // QCK light PointCloud upload requires an explicit cancel request for that measurement if the upload has failed
      if (
        metadata.metadataType === MetadataType.PointCloud &&
        isDefined(lastResponse) &&
        'measurementId' in lastResponse &&
        isDefined(lastResponse.measurementId)
      ) {
        API.qcklightUploadApi.getUploadCancelMeasurementid(lastResponse.measurementId);
      }

      onErrorOrig?.(errorCode, error);
    };

    const onSuccessAllChunksCompleted = onSuccess;
    // normalize the progress indicator for the initial chunk to be relative to the overall size (otherwise it would jump from 0% to 100% when the chunk is uploaded)
    const onProgressOrig = onProgress;
    onProgress = (value: number) => Math.floor((value * initialChunkSize) / file.size); // make progress relative to the overall size

    // provide custom onSuccess handler which uploads remaining in sequence after first chunk
    onSuccess = async (data) => {
      // begin with uploading the remaining parts

      const onPartialSuccess = (response: RHIMMeasurementServiceV1ModelsUploadPtsResponseDto | SuccessResponseType) => (lastResponse = response);

      const onPartialProgress = (value: number, completedChunks: number) => {
        const overallProgress = (100 * completedChunks + value) / chunkCount;
        onProgressOrig?.(Math.floor(overallProgress));
      };

      let lastUpload: Promise<void> | undefined; // tracks if the chunk upload is still in progress while we already process the next chunk

      // offset by 1 due to initial chunk existing already
      for (let i = 2; i <= chunkCount + 1; ++i) {
        // new FormData has shape RHIMMeasurementServiceV1ModelsUploadPtsChunkDto
        const slice = file.slice(chunkStart, chunkStart + chunkSize);
        const chunk = hasGzipCompression ? await compressGzip(slice) : slice;

        const partFormData = new FormData();
        if (!isLesPointCloud) {
          partFormData.set('chunkNumber', i.toString());
          partFormData.set('chunkSize', slice.size.toString());
        }

        partFormData.set('file', chunk, file.name);
        let uploadId: string;

        if (isLesPointCloud) {
          assert('fileIngressId' in data, 'Expected response to contain the fileIngressId');
          uploadId = data.fileIngressId as string;
        } else {
          assert('measurementId' in data, 'Expected response to contain the measurementId');
          uploadId = data.measurementId as string;
        }

        /**
         * Response: RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
         * Chunks: RHIMMeasurementServiceV1ModelsUploadPtsChunkDto
         * postUploadPartMeasurementid /Upload/part/{uploadId}
         *  200: RHIMMeasurementServiceV1ModelsUploadPtsResponseDto
         *  400: RHIMMeasurementServiceV1ModelsUploadValidationResponseDto
         *  422: RHIMAPOCoreWebProblemDetailsApoProblemDetails
         *  */

        const uploadEndpointURL = getPartialUploadEndpointURL(metadata, uploadId);

        // we allow the compression to be performed while the previous chunk upload is still in progress.
        // But before we do the next chunk upload, we want to wait for the previous one to finish first
        await lastUpload;
        lastUpload = Api.postUpload(
          { formData: partFormData, uploadEndpointURL, cancelToken },
          (value) => onPartialProgress(value, i - 2),
          onPartialSuccess,
          (error) => {
            onError?.(ErrorCode.Default, { ...error, title: 'Chunk upload failed' }); // TODO: proper error handling
            throw error;
          },
          addTimestampHeader()
        );

        chunkStart += chunkSize;
      }

      // at this stage, all chunks were successfully uploaded in sequence
      await lastUpload;
      onProgressOrig?.(100);

      if (!isLesPointCloud) {
        assert(isDefined(lastResponse) && 'pointCloudFileId' in lastResponse, 'Expected response to contain the pointCloudFileId');
        assert(isDefined(lastResponse.pointCloudFileId), 'Expected pointCloudFileId to be a string');
        onSuccessAllChunksCompleted?.({
          type: 'point-cloud',
          ...lastResponse,
          pointCloudFileId: lastResponse.pointCloudFileId,
        });
      } else {
        onSuccessAllChunksCompleted?.({
          type: 'point-cloud',
        } as SuccessResponseType);
      }
    };
  } else {
    formData.append('file', file);
  }

  const handleError = (axiosError: AxiosError<APO.ErrorResponse>) => {
    /**
     * FUTURE: These error messages should be derived from file.error.
     * As of now, we don't have an API to respond with propers errors.
     *
     * FUTURE: if (file.error.status === 413) it means the file was too large
     * and we can indicate that by introducing another ErrorCode and its message.
     */
    const errorResponse: APO.ErrorResponse | APO.ErrorResponse401 | string = axiosError.response ? axiosError.response.data : axiosError.message;
    const status = axiosError.response?.status ?? ErrorCode.Default;

    let error;

    if (isErrorResponse401(errorResponse)) {
      error = { title: errorResponse.message };
    } else if (isErrorResponse(errorResponse)) {
      error = {
        title: errorResponse.title,
        message: errorResponse.detail,
      };
    } else if (isString(errorResponse)) {
      error = { title: errorResponse };
    }
    onError?.(status, error);
  };

  const uploadEndpointURL = getUploadEndpointURL(metadata);

  Api.postUpload({ uploadEndpointURL, formData, cancelToken: cancelToken }, onProgress, onSuccess, handleError, addTimestampHeader());
};

function getUploadEndpointURL(metadata: IFileUploadMetadata | ILesFileUploadMetadata | IFilePointCloudUploadMetadata) {
  switch (metadata.metadataType) {
    case MetadataType.Default:
      return `/api/datacenter/v2/upload/${metadata.customerId}`;
    case MetadataType.LesPointCloud:
      return `/api/fileingestionservice/v1/upload/${metadata.customerId}`;
    case MetadataType.PointCloud:
      return `/api/measurementservice/v1/Upload/begin/${metadata.vesselId}`;
  }
}

function getPartialUploadEndpointURL(metadata: IFileUploadMetadata | ILesFileUploadMetadata | IFilePointCloudUploadMetadata, uploadId: string) {
  switch (metadata.metadataType) {
    case MetadataType.LesPointCloud:
      return `/api/fileingestionservice/v1/upload/part/${uploadId}`;
    case MetadataType.PointCloud:
      return `/api/measurementservice/v1/Upload/part/${uploadId}`;
    default:
      throw new Error('Unsupported metadata type');
  }
}
