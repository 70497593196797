import { settings } from '@rhim/design';
import { FileGenericIcon, OpsStateDeactivatedIcon } from '@rhim/icons/24';
import formatFilesize from 'filesize';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  onSelectedFileRemoved: () => void;
  fileName: string;
  fileSize: number;
}

export const FileSelected: React.ChildlessComponent<Props> = ({ fileName, fileSize, onSelectedFileRemoved }) => {
  const { t } = useTranslation(['ingress']);

  return (
    <Wrapper>
      <SIconFileGeneric />
      <SFileInfoContainer>
        <SFileName>{fileName}</SFileName>
        <SFileSize>
          {formatFilesize(fileSize)} {t('ingress:fileSelectedReadyForUploadSuffix')}
        </SFileSize>
      </SFileInfoContainer>
      <SVerticalDivider />
      <SIconOpsStateDeactivated fill={settings.colors.Primary.Blue_9} onClick={onSelectedFileRemoved} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 3px;
  border: 1px solid ${settings.colors.Primary.Grey_3};
`;

const SIconFileGeneric = styled(FileGenericIcon)`
  margin-left: ${settings.Spacing.Spacing_400};
  flex-shrink: 0;
`;

const SFileInfoContainer = styled.div`
  margin: ${settings.Spacing.Spacing_300} ${settings.Spacing.Spacing_400};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const SFileName = styled.span`
  color: ${settings.colors.Primary.Grey_8};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  line-height: ${settings.typography.LineHeight.Line_Height_16};
`;

const SFileSize = styled.span`
  margin-top: ${settings.Spacing.Spacing_100};
  color: ${settings.colors.Primary.Grey_6};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
`;

const SVerticalDivider = styled.div`
  width: 1px;
  height: auto;
  align-self: stretch;
  background-color: ${settings.colors.Primary.Grey_2};
`;

const SIconOpsStateDeactivated = styled(OpsStateDeactivatedIcon)`
  flex-shrink: 0;
  margin: 0 ${settings.Spacing.Spacing_300};
  cursor: pointer;
`;
