import { settings } from '@rhim/design';
import { partial } from '@rhim/utils';
import { Table, TableProps } from 'antd';
import { ConfigProvider } from 'antd';
import { ColumnType } from 'antd/es/table';
import { Component } from 'react';
import styled, { css } from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<T> extends TableProps<T> {
  background?: 'default' | 'grey';
}

export const HEADER_HEIGHT_PX = 48;
export const HEADER_HEIGHT_SMALL_PX = 32;

export type ColumnTableType<T> = ColumnType<T>;
class DataTable<T extends object> extends Component<Props<T>> {
  static defaultProps = partial<Props<unknown>>()({
    showSorterTooltip: false,
    background: 'default',
  });

  render(): JSX.Element {
    return (
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 8,
              paddingMD: 8,
              paddingSM: 8,
              colorText: settings.colors.Primary.Grey_8,
            },
          },
        }}
      >
        <STable {...this.props} />
      </ConfigProvider>
    );
  }
}

// Design :
// https://app.zeplin.io/project/5eb293edf1f9a7763fec7596/screen/5f746ea840363a4885a5f168
const STable = styled(Table)<Pick<Props<unknown>, 'background'>>((props) => {
  const headerBackgroundColor: React.CSSProperties['background'] = (() => {
    switch (props.background) {
      case 'default':
        return settings.colors.Primary.Grey_1;
      case 'grey':
        return settings.colors.Monochromatic.White;
      default:
        return settings.colors.Primary.Grey_1;
    }
  })();

  const rowBackgroundColor: React.CSSProperties['background'] = (() => {
    switch (props.background) {
      case 'default':
        return settings.colors.Monochromatic.White;
      case 'grey':
        return settings.colors.Primary.Grey_1;
      default:
        return settings.colors.Monochromatic.White;
    }
  })();

  const rowHoverBackgroundColor: React.CSSProperties['background'] = (() => {
    switch (props.background) {
      case 'default':
        return settings.colors.Primary.Grey_1;
      case 'grey':
        return settings.colors.Primary.Grey_2;
      default:
        return settings.colors.Primary.Grey_1;
    }
  })();

  return css`
    /* REMOVE ROUNDED CORNERS */
    .ant-table .ant-table-header {
      border-radius: 0;
    }

    .ant-table-container table > thead > tr:first-child {
      > *:first-child {
        border-start-start-radius: 0;
      }

      > *:last-child {
        border-start-end-radius: 0;
      }
    }

    /* TABLE HEADER */
    .ant-table-thead {
      height: ${props.size === 'small' ? HEADER_HEIGHT_SMALL_PX : HEADER_HEIGHT_PX}px;

      > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background: ${settings.colors.Primary.Grey_1};
        top: 0;
        transform: none;
        height: 100%;
      }

      > tr > th,
      > tr > td {
        padding: 0 ${settings.Spacing.Spacing_200};
        border-bottom: 1px solid ${settings.colors.Primary.Grey_3};
      }

      > tr > th,
      > tr > td,
      th.ant-table-column-sort {
        background: ${headerBackgroundColor};
        color: ${settings.colors.Primary.Grey_8};
        font-family: ${settings.typography.FontFamily.Bold};
        font-size: ${props.size === 'small' ? settings.typography.FontSize.X_Small : settings.typography.FontSize.Small};
      }

      /* BACKGROUND COLOR OF HOVERED HEADER CELL WITH SORTING CAPABILITY */
      th.ant-table-column-has-sorters:hover,
      th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover {
        background: ${settings.colors.Primary.Grey_3};
      }

      /* COLUMN SORTING UP/DOWN ARROWS FILL COLOR */
      .ant-table-column-sorter {
        .anticon {
          color: ${settings.colors.Primary.Grey_5};
        }

        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          color: ${settings.colors.Primary.Blue_9};
        }
      }
    }

    /* ROW BACKGROUND COLOR */
    .ant-table,
    .ant-table-cell-fix-left,
    td.ant-table-column-sort,
    .ant-table-cell-fix-right {
      background: ${rowBackgroundColor};
    }

    /* HOVERED ROW BACKGROUND COLOR */
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: ${rowHoverBackgroundColor};
    }

    /* ROW BACKGROUND COLOR WHEN AN INPUT FIELD INSIDE IS FOCUSED */
    tr:focus-within,
    .ant-table-tbody > tr:focus-within.ant-table-row:hover > td,
    .ant-table-tbody > tr:focus-within > td.ant-table-cell-row-hover {
      background: ${settings.colors.Primary.Blue_2};
    }

    /* ROW BOTTOM BORDER */
    .ant-table-tbody > tr > td {
      padding: ${settings.Spacing.Spacing_100} ${settings.Spacing.Spacing_200};
      height: ${settings.Spacing.Spacing_600};
      border-bottom: 1px solid ${settings.colors.Primary.Grey_3};
    }
  `;
}) as typeof Table;

export default DataTable;
