import { i18nReact } from '@rhim/i18n';
import { Legal, Tabs } from '@rhim/react';
import { FC } from 'react';

import LegalTabImprint from './LegalTabImprint';
import LegalTabPrivacy from './LegalTabPrivacy';
import LegalTabTermsOfUse from './LegalTabTermsOfUse';

const LegalPage: FC<React.PropsWithChildren> = () => {
  const { t } = i18nReact.useTranslation(['static']);

  return (
    <Legal.LegalPage title={t('static:legal.title')}>
      <Tabs.TabPane tab={t('static:legal.topics.imprint.title')} key="/imprint">
        <LegalTabImprint />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('static:legal.topics.privacy.title')} key="/privacy">
        <LegalTabPrivacy />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('static:legal.topics.terms-of-use.title')} key="/terms-of-use">
        <LegalTabTermsOfUse />
      </Tabs.TabPane>
    </Legal.LegalPage>
  );
};

export default LegalPage;
