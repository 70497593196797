import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const AlarmWarningIndicatorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="m1 8 7-7 7 7-7 7-7-7z" fill={fill} />
      <path
        d="M.646 7.646a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708 0l7-7a.5.5 0 0 0 0-.708l-7-7a.5.5 0 0 0-.708 0l-7 7z"
        stroke={settings.colors.Operational.State_Alert_Orange_4}
        strokeLinejoin="round"
      />
    </svg>
  );
};

AlarmWarningIndicatorIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Alert_Orange_2,
};
