import { settings } from '@rhim/design';
import { CheckmarkIcon, ChevronDownIcon, ChevronDownMiniIcon, XCircledIcon } from '@rhim/icons/16';
import { optionContentLabel } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import { ConfigProvider, Select as AntdSelect } from 'antd';
import { DefaultOptionType, LabeledValue } from 'antd/lib/select';
import classNames from 'classnames';
import React, { CSSProperties, FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { FieldLabel, FieldLabelProps } from '../../partials';
import { Tooltip } from '../Tooltip';

const { Option: AntdOption, OptGroup: AntdOptGroup } = AntdSelect;
export const Option = AntdOption;
export const OptGroup = AntdOptGroup;

const CLEAR_ICON = <XCircledIcon />;
const CLEAR_ICON_SIZE_PX = 16;

type OptionProps = {
  label: string;
  icon?: React.ReactElement;
  showSelectedItemCheckmark?: boolean;
};
/**
 * NOTE: keep the ...rest as shown in the line below.
 * Otherwise if this component is wrapped within an antd Tooltip (as is the case with the OptionContentwithTooltip further down) then
 * the tooltip will not work.
 *  */
export const OptionContent = React.forwardRef<HTMLDivElement, OptionProps>(({ label, icon, showSelectedItemCheckmark = false, ...rest }, ref) => {
  return (
    <SOptionContentWrapper ref={ref} {...rest}>
      {isDefined(icon) && <SIcon>{icon}</SIcon>}
      <SOptionContentLabel data-test-id={optionContentLabel}>{label}</SOptionContentLabel>
      {showSelectedItemCheckmark && (
        <>
          <SHorizontalFiller />
          <SIconCheckmark className="iconCheckmark">
            <CheckmarkIcon fill={settings.colors.Primary.Grey_4} />
          </SIconCheckmark>
        </>
      )}
    </SOptionContentWrapper>
  );
});

OptionContent.displayName = 'OptionContent';

interface OptionTooltipProps extends OptionProps {
  getPopupContainer?: (node: HTMLElement) => HTMLDivElement | HTMLElement;
  tooltipTitle?: string;
}

export const OptionContentwithTooltip: React.ChildlessComponent<OptionTooltipProps> = ({
  label,
  icon,
  showSelectedItemCheckmark,
  tooltipTitle,
  getPopupContainer,
}) => {
  return (
    <Tooltip title={tooltipTitle ?? label} placement="right" getPopupContainer={getPopupContainer} destroyTooltipOnHide={true}>
      <OptionContent label={label} icon={icon} showSelectedItemCheckmark={showSelectedItemCheckmark} />
    </Tooltip>
  );
};

export interface SelectProps {
  dataTestId?: string;
  colorTheme?: 'light' | 'grey' | 'dark';
  className?: string;
  prefixIcon?: React.ReactElement;
  label?: FieldLabelProps;
  placeholder?: string;
  defaultValue?: string;
  maxTagCount?: number;
  options?: DefaultOptionType[];
  value?: string | string[];
  size?: 'x-small-32' | 'small-40' | 'medium-48' | 'large-56';
  onSelect?: (value: string) => void;
  suffixIcon?: React.ReactElement;
  bordered?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  dropdownMatchSelectWidth?: boolean;
  dropdownStyle?: CSSProperties;
  showSearch?: boolean;
  notFoundContent?: React.ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOption?: any;
  allowClear?: boolean;
  disabled?: boolean;
  open?: boolean;
  onDropdownVisibleChange?: (isOpen: boolean) => void;
  onSearch?: (value: string) => void;
  searchValue?: string;
  defaultOpen?: boolean;
  dropdownRender?: (originNode: React.ReactElement) => React.ReactElement;
  mode?: 'multiple' | 'tags';
  style?: CSSProperties;
  onChange?: (value: string | string[]) => void;
}

export const Select: FC<React.PropsWithChildren<SelectProps>> = ({
  dataTestId,
  colorTheme,
  className,
  prefixIcon,
  label,
  placeholder,
  defaultValue,
  value,
  size = 'large-56',
  suffixIcon,
  onSelect,
  children,
  ...props
}) => {
  const handleOnSelect = (newValue: string | number | LabeledValue | null) => {
    if (isDefined(onSelect)) {
      onSelect(newValue as string);
    }
  };

  const iconSuffix: React.ReactElement = useMemo(() => {
    if (isDefined(suffixIcon)) {
      return suffixIcon;
    }
    switch (size) {
      case 'x-small-32':
        return <ChevronDownMiniIcon fill="var(--color)" />;
      default:
        return <ChevronDownIcon fill="var(--color)" />;
    }
  }, [suffixIcon, size]);

  const styledClassNames = classNames({
    [`size-${size}`]: true,
    [colorTheme ?? 'light']: true,
  });

  return (
    <SWrapper className={className}>
      <AntStylesheetOverrides />
      {label && <FieldLabel text={label.text} isRequired={label.isRequired} />}
      <SAntdSelectWrapper hasPrefixIcon={isDefined(prefixIcon)} size={size}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorTextDisabled: settings.colors.Primary.Grey_4,
                colorBgContainerDisabled: settings.colors.Primary.Grey_2,
              },
            },
          }}
        >
          <AntdSelect
            data-test-id={dataTestId}
            defaultValue={defaultValue}
            value={value}
            placeholder={placeholder}
            onSelect={handleOnSelect}
            className={styledClassNames}
            popupClassName={styledClassNames}
            suffixIcon={iconSuffix}
            clearIcon={CLEAR_ICON}
            {...props}
          >
            {children}
          </AntdSelect>
        </ConfigProvider>
        {prefixIcon && <SPrefixIcon>{prefixIcon}</SPrefixIcon>}
      </SAntdSelectWrapper>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SPrefixIcon = styled.span<Pick<SelectProps, 'size'>>((props) => {
  const offsetLeft: React.CSSProperties['left'] = (() => {
    switch (props.size) {
      case 'large-56':
        return settings.Spacing.Spacing_300;
      default:
        return settings.Spacing.Spacing_200;
    }
  })();

  return css`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${offsetLeft};
  `;
});

const AntStylesheetOverrides = settings.globals.patchedCreateGlobalStyle`
  .ant-select-disabled {
    .ant-select-selector {
      border-color: ${settings.colors.Primary.Grey_2} !important;
    }
  }

  .ant-select.ant-select-single.ant-select-focused.ant-select-open.ant-select-show-search .ant-select-selection-item {
    cursor: text;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 3px;
  }

  .ant-select.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    font-size: var(--font-size);
  }

  .ant-select-selector *,
  .ant-select-dropdown * {
    font-family: ${settings.typography.FontFamily.Medium};
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    color: ${settings.colors.Primary.Grey_8};
    display: flex;
    align-items: center;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: ${settings.colors.Operational.State_Notif_Magenta_2} !important;
  }

  .ant-select-dropdown {
    border: 1px ${settings.colors.Primary.Grey_3};
    padding: ${settings.Spacing.Spacing_50} 0;
  }

  .ant-select-dropdown,
  .rc-virtual-list-holder-inner .ant-select-item {
    border-radius: 0;
  }

  .ant-select,
  .ant-select-dropdown,
  .rc-virtual-list-holder-inner .ant-select-item {
    svg {
      color: var(--color);
    }

    --height: 40px;
    --iconWidth: 16px;

    &.light {
      --background-color: ${settings.colors.Monochromatic.White};
      --color: ${settings.colors.Primary.Grey_8};
      --hover-border-color: ${settings.colors.Primary.Blue_8};
      --border-color: ${settings.colors.Primary.Grey_8};
    }

    &.dark {
      --background-color: ${settings.colors.Primary.Grey_10};
      --color: ${settings.colors.Primary.Grey_2};
      --hover-border-color: ${settings.colors.Primary.Grey_9};
      --border-color: ${settings.colors.Primary.Grey_7};
    }

     &.grey {
      --background-color: ${settings.colors.Primary.Grey_1};
      --color: ${settings.colors.Primary.Grey_8};
      --hover-border-color: ${settings.colors.Primary.Grey_1};
      --border-color: ${settings.colors.Primary.Grey_1};

    }

    &.size-x-small-32 {
      --height: 32px;
      --font-size: ${settings.typography.FontSize.Small};
      --horizontalMargin: ${settings.Spacing.Spacing_150};
    }

    &.size-small-40 {
      --height: 40px;
      --font-size: ${settings.typography.FontSize.Small};
      --horizontalMargin: ${settings.Spacing.Spacing_200};
    }

    &.size-medium-48 {
      --height: 48px;
      --font-size: ${settings.typography.FontSize.Medium};
      --horizontalMargin: ${settings.Spacing.Spacing_200};
    }

    &.size-large-56 {
      --height: 56px;
      --font-size: ${settings.typography.FontSize.Large};
      --horizontalMargin: ${settings.Spacing.Spacing_300};
    }

    &.ant-select-disabled, &.ant-select-disabled svg {
      --color: ${settings.colors.Primary.Grey_4};
    }

    .ant-select-item-empty {
      color: ${settings.colors.Primary.Grey_4};
      padding: ${settings.Spacing.Spacing_50} ${settings.Spacing.Spacing_150};
    }

    font-size: var(--font-size);

    .ant-select-selector {
      font-size: var(--font-size);
      background-color: var(--background-color);
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: var(--hover-border-color) !important;
      box-shadow: none !important;
    }

    &.ant-select-open, &.ant-select-focused .ant-select-selector {
      border-color: var(--border-color) !important;
      box-shadow: none !important;
    }

    &.ant-select-single {
      height: var(--height);
    }

    &.ant-select-multiple, &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: var(--height);
    }

    &.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-right: calc(var(--iconWidth) + var(--horizontalMargin));
    }

    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-item:not(.ant-select-item-group):not(.ant-select-item-option-grouped) {
      height: var(--height);
      padding: 0 var(--horizontalMargin);
      transition: none;
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
      color: ${settings.colors.Primary.Grey_4};
    }

    .ant-select-selection-item span.iconCheckmark {
      display: none;
    }

    .ant-select-selection-item {
      width: max-content;
      color: var(--color) !important;
    }

    &:not(.ant-select-disabled) .ant-select-selection-item {
      cursor: pointer;
    }

    .ant-select-selection-item,
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }

    &.ant-select-item-option,
    .ant-select-dropdown .ant-select-item-option {
      color: ${settings.colors.Primary.Grey_8};
    }

    .ant-select-arrow {
      display: flex;
      margin-top: 0;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      width: auto;
      right: var(--horizontalMargin);
    }

    .ant-select-clear {
      width: ${CLEAR_ICON_SIZE_PX}px;
      height: ${CLEAR_ICON_SIZE_PX}px;
      right: var(--horizontalMargin);
      margin-top: 0;
      transform: translateY(-50%);
    }

    .ant-select-item-option {
      color: var(--color);

      &.ant-select-item-option-active {
        background-color: ${settings.colors.Primary.Grey_2};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: white;
      background-color: ${settings.colors.Primary.Blue_9};

      svg {
        color: white;
      }
    }

    .ant-select-item-option-disabled {
      opacity: 40%;
    }
  }
`;

const SAntdSelectWrapper = styled.div<{ hasPrefixIcon: boolean } & Pick<SelectProps, 'size'>>((props) => {
  const marginLeft: React.CSSProperties['marginLeft'] = (() => {
    if (!props.hasPrefixIcon) {
      return 0;
    }
    switch (props.size) {
      case 'large-56':
        return '36px';
      default:
        return '24px';
    }
  })();

  return css`
    position: relative;

    .ant-select {
      width: 100%;
      padding: 0;
    }

    span.ant-select-selection-item {
      margin-left: ${marginLeft};
    }
  `;
});

const SIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_150};
`;

const SOptionContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const SHorizontalFiller = styled.span`
  flex-grow: 1;
`;

const SIconCheckmark = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${settings.Spacing.Spacing_200};
`;

const SOptionContentLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
