/**
 * Please follow the naming convention: <name of Element> <type of Element> <location of Element>
 * e.g. customersDropdownAppBar
 */

//Error Modal
export const refreshPageButtonErrorModal = 'refreshPageButtonErrorModal';
export const logOutButtonErrorModal = 'logOutButtonErrorModal';

//AppBar
export const customersDropdownOptions = 'customersDropdownOptions';
export const dataCenterLinkAppBar = 'dataCenter';
export const settingsLinkAppBar = 'settings';
export const userLinkAppBar = 'avatar';
export const englishOptionAppBar = 'en';
export const portugueseOptionAppBar = 'pt_BR';

//Sidebar
export const ladleOverviewFeature = 'ladleOverviewFeature';
export const wearExplorerFeature = 'wearExplorerFeature';
export const measurementViewFeature = 'measurementViewFeature';
export const connectedMachinesFeature = 'connectedMachinesFeature';
export const volumeDashboardFeature = 'volumeDashboardFeature';
export const predictionReportFeature = 'predictionReportFeature';
export const operatorDisplayFeature = 'operatorDisplayFeature';
export const parametersComparisonFeature = 'parametersComparisonFeature';
export const dataCenterFeature = 'dataCenterFeature';
export const measurementDataFeature = 'measurementDataFeature';

//Error Panel
export const noCampaignsFoundTitleErrorPanel = 'noCampaignsFound';
export const incompleteSetupPanel = 'customerHasNoVessels';

//Page Not Found
export const pageNotFoundContainer = 'pageNotFound';

//RHIM Message Panel
export const headerRhimMessagePanelContainer = 'header';
export const messageRhimMessagePanelContainer = 'message';

// Fleet Overview
export const fleetOverviewContainerFleetOverview = 'fleetOverviewContainerFleetOverview';
export const titleFleetOverview = 'titleFleetOverview';
export const vesselsCountLabelFleetOverview = 'vesselsCountLabelFleetOverview';
export const vesselCardContainersFleetOverview = 'vesselCardContainersFleetOverview';
export const vesselCardNameLabelsFleetOverview = 'vesselCardNameLabelsFleetOverview';
export const criticalIconLiningThicknessBarFleetOverview = 'criticalIconLiningThicknessBarFleetOverview';
export const circularProgressContainerFleetOverview = 'circularProgressContainerFleetOverview';
export const liningThicknessBarContainerFleetOverview = 'liningThicknessBarContainerFleetOverview';
export const minimumValueBarLiningThicknessBarFleetOverview = 'minimumValueBarLiningThicknessBarFleetOverview';
export const maximumValueBarLiningThicknessBarFleetOverview = 'maximumValueBarLiningThicknessBarFleetOverview';

//Data Center
export const dataCenterContainerDataCenter = 'datacenter';
export const infoPanelContainerDataCenter = 'ingressWelcome';
export const processDataPanelContainerDataCenter = 'pdata';
export const measurementDataPanelContainerDataCenter = 'mdata';
export const pointCloudDataPanelContainerDataCenter = 'pointCloudData';

export const processDataUploadContainerDataCenter = 'processDataUploadContainer';
export const measurementDataUploadContainerDataCenter = 'measurementDataUploadContainer';
export const pointCloudScansDataUploadContainerDataCenter = 'pointCloudScansDataUploadContainer';

export const stepsContainer = 'stepsContainer';
export const itemsStepsContainer = 'itemsStepsContainer';

export const headerUploadContainerDataCenter = 'header';

export const doneMessageUploadContainer = 'doneUploadingLabel';

//Process Data Upload Container - Data Center
export const uploadPanelProcessDataUploadContainer = 'processDataUpload';
export const backButtonProcessDataUploadContainer = 'back';
export const doneButtonProcessDataUploadContainer = 'done-button';
export const retryButtonProcessDataUploadContainer = 'retryButton';

//Measurement Data Upload Container - Data Center
export const postMortemUploadPanelMeasurementDataUploadContainer = 'postMortemUpload';
export const filteredUploadPanelMeasurementDataUploadContainer = 'filteredUpload';
export const vertexUploadPanelMeasurementDataUploadContainer = 'vertexUpload';
export const mpmUploadPanelMeasurementDataUploadContainer = 'mpmUpload';
export const stlUploadPanelMeasurementDataUploadContainer = 'stlUpload';
export const summaryDataUploadPanelMeasurementDataUploadContainer = 'summaryDataUpload';
export const backButtonMeasurementDataUploadContainer = 'back';
export const doneButtonMeasurementDataUploadContainer = 'done-button';

//Point Cloud Scans Data Upload Container - Data Center
export const pointCloudScanDataContainer = 'pointCloudScanDataContainer';
export const rblWearCalculationsDescriptionContainerPointCloudScansDataUploadContainer =
  'rblWearCalculationsDescriptionContainerPointCloudScansDataUploadContainer';
export const rblWearCalculationsVesselTypeSelectionContainerPointCloudScansDataUploadContainer =
  'rblWearCalculationsVesselTypeSelectionContainerPointCloudScansDataUploadContainer';
export const viewOnlyDescriptionContainerPointCloudScansDataUploadContainer = 'viewOnlyDescriptionContainerPointCloudScansDataUploadContainer';
export const viewOnlyVesselTypeSelectionContainerPointCloudScansDataUploadContainer = 'viewOnlyVesselTypeSelectionContainerPointCloudScansDataUploadContainer';
export const uploadProgressContainerPointCloudScansDataUploadContainer = 'uploadProgressContainerPointCloudScansDataUploadContainer';
export const uploadAndPreviewContainerPointCloudScansDataUploadContainer = 'uploadAndPreviewContainerPointCloudScansDataUploadContainer';
export const previewContainerPointCloudScansDataUploadContainer = 'previewContainerPointCloudScansDataUploadContainer';
export const vesselDropdownPointCloudScansDataUploadContainer = 'pointCloudScansUploadLadleDropdown';
export const ladleDropdownOptionsPointCloudScansDataUploadContainer = 'pointCloudScansUploadLadleDropdownOptions';
export const vesselFormItemPointCloudScansDataUploadContainer = 'vesselFormItemPointCloudScansDataUploadContainer';
export const campaignTextBoxPointCloudScansDataUploadContainer = 'campaignTextBox';
export const campaignFormItemPointCloudScansDataUploadContainer = 'campaignFormItemPointCloudScansDataUploadContainer';
export const heatNumberTextBoxPointCloudScansDataUploadContainer = 'heatNumberTextBox';
export const heatNumberFormItemPointCloudScansDataUploadContainer = 'heatNumberFormItemPointCloudScansDataUploadContainer';
export const dateOfMeasurementTextBoxPointCloudScansDataUploadContainer = 'dateOfMeasurementTextBox';
export const dateOfMeasurementFormItemPointCloudScansDataUploadContainer = 'dateOfMeasurementFormItemPointCloudScansDataUploadContainer';
export const timeOfMeasurementTextBoxPointCloudScansDataUploadContainer = 'timeOfMeasurementTextBox';
export const timeOfMeasurementFormItemPointCloudScansDataUploadContainer = 'timeOfMeasurementFormItemPointCloudScansDataUploadContainer';
export const campaignTextBoxErrorMessagePointCloudScansDataUploadContainer = 'campaignTextBoxErrorMessageLabel';
export const heatNumberTextBoxErrorMessagePointCloudScansDataUploadContainer = 'HeatNumberTextBoxErrorMessageLabel';
export const vesselLiningDropdownPointCloudScansDataUploadContainer = 'vesselLiningDropdownPointCloudScansDataUploadContainer';
export const vesselLiningDropdownOptionsPointCloudScansDataUploadContainer = 'vesselLiningDropdownOptionsPointCloudScansDataUploadContainer';
export const vesselLiningFormItemPointCloudScansDataUploadContainer = 'vesselLiningFormItemPointCloudScansDataUploadContainer';
export const liningTemperatureDropdownPointCloudScansDataUploadContainer = 'liningTemperatureDropdownPointCloudScansDataUploadContainer';
export const liningTemperatureDropdownOptionsPointCloudScansDataUploadContainer = 'liningTemperatureDropdownOptionsPointCloudScansDataUploadContainer';
export const liningTemperatureFormItemPointCloudScansDataUploadContainer = 'liningTemperatureFormItemPointCloudScansDataUploadContainer';
export const notesFormItemPointCloudScansDataUploadContainer = 'notesFormItemPointCloudScansDataUploadContainer';
export const notesTextAreaPointCloudScansDataUploadContainer = 'notesTextAreaPointCloudScansDataUploadContainer';
export const liningConditionDropdownPointCloudScansDataUploadContainer = 'liningConditionDropdownPointCloudScansDataUploadContainer';
export const liningConditionDropdownOptionsPointCloudScansDataUploadContainer = 'liningConditionDropdownOptionsPointCloudScansDataUploadContainer';
export const liningConditionFormItemPointCloudScansDataUploadContainer = 'liningConditionFormItemPointCloudScansDataUploadContainer';
export const maintenanceTypeDropdownPointCloudScansDataUploadContainer = 'maintenanceTypeDropdownPointCloudScansDataUploadContainer';
export const maintenanceTypeDropdownOptionsPointCloudScansDataUploadContainer = 'maintenanceTypeDropdownOptionsPointCloudScansDataUploadContainer';
export const maintenanceTypeFormItemPointCloudScansDataUploadContainer = 'maintenanceTypeFormItemPointCloudScansDataUploadContainer';
export const confirmCheckBoxPointCloudScansDataUploadContainer = 'confirmCheckBoxPointCloudScansDataUploadContainer';
export const confirmFormItemPointCloudScansDataUploadContainer = 'confirmFormItemPointCloudScansDataUploadContainer';
export const deleteButtonPointCloudScansDataUploadContainer = 'deleteButton';
export const uploadPanelPointCloudScansDataUploadContainer = 'pointCloudScanDataUpload';
export const backButtonPointCloudScansDataUploadContainer = 'back';
export const startUploadButtonPointCloudScansDataUploadContainer = 'primaryActionButton';
export const pairStepSelectorPrevious = 'pairStepSelectorPrevious';
export const pairStepSelectorNext = 'pairStepSelectorNext';
export const pairStepFinishRegistration = 'pairStepFinishRegistration';

//Point Cloud Up Axis Alignment Container
export const pointCloudUpAxisAlignmentContainer = 'pointCloudUpAxisAlignmentContainer';
export const headerPointCloudUpAxisAlignmentContainer = 'headerPointCloudUpAxisAlignmentContainer';
export const instructionsLabelPointCloudUpAxisAlignmentContainer = 'instructionsLabelPointCloudUpAxisAlignmentContainer';
export const examplesContainerPointCloudUpAxisAlignmentContainer = 'examplesContainerPointCloudUpAxisAlignmentContainer';
export const correctExampleContainerExamplesContainerPointCloudUpAxisAlignmentContainer =
  'correctExampleContainerExamplesContainerPointCloudUpAxisAlignmentContainer';
export const misalignedExampleContainerExamplesContainerPointCloudUpAxisAlignmentContainer =
  'misalignedExampleContainerExamplesContainerPointCloudUpAxisAlignmentContainer';
export const verticalSliderContainerPointCloudUpAxisAlignmentContainer = 'verticalSliderContainerPointCloudUpAxisAlignmentContainer';
export const angleTextBoxVerticalSliderContainerPointCloudUpAxisAlignmentContainer = 'angleTextBoxVerticalSliderContainerPointCloudUpAxisAlignmentContainer';
export const horizontalSliderContainerPointCloudUpAxisAlignmentContainer = 'horizontalSliderContainerPointCloudUpAxisAlignmentContainer';
export const angleTextBoxHorizontalSliderContainerPointCloudUpAxisAlignmentContainer =
  'angleTextBoxHorizontalSliderContainerPointCloudUpAxisAlignmentContainer';
export const resetRotationsButtonPointCloudUpAxisAlignmentContainer = 'resetRotationsButtonPointCloudUpAxisAlignmentContainer';
export const fullScreenButtonPointCloudUpAxisAlignmentContainer = 'fullScreenButtonPointCloudUpAxisAlignmentContainer';
export const threeDContainerPointCloudUpAxisAlignmentContainer = 'threeDContainerPointCloudUpAxisAlignmentContainer';
export const topLabelThreeDContainerPointCloudUpAxisAlignmentContainer = 'topLabelThreeDContainerPointCloudUpAxisAlignmentContainer';
export const rotationalLabelThreeDContainerPointCloudUpAxisAlignmentContainer = 'rotationalLabelThreeDContainerPointCloudUpAxisAlignmentContainer';
export const rotateIconThreeDContainerPointCloudUpAxisAlignmentContainer = 'rotateIconThreeDContainerPointCloudUpAxisAlignmentContainer';
export const rightLabelThreeDContainerPointCloudUpAxisAlignmentContainer = 'rightLabelThreeDContainerPointCloudUpAxisAlignmentContainer';
export const frontLabelThreeDContainerPointCloudUpAxisAlignmentContainer = 'frontLabelThreeDContainerPointCloudUpAxisAlignmentContainer';
export const verifyAlignmentCheckBoxPointCloudUpAxisAlignmentContainer = 'verifyAlignmentCheckBoxPointCloudUpAxisAlignmentContainer';
export const acceptAlignmentAndContinueButtonPointCloudUpAxisAlignmentContainer = 'acceptAlignmentAndContinueButtonPointCloudUpAxisAlignmentContainer';

//Point Cloud Height Registration Container - Data Center
export const pointCloudHeightRegistrationContainer = 'pointCloudHeightRegistrationContainer';
export const headerPointCloudHeightRegistrationContainer = 'headerPointCloudHeightRegistrationContainer';
export const instructionsLabelPointCloudHeightRegistrationContainer = 'instructionsLabelPointCloudHeightRegistrationContainer';
export const fullScreenButtonPointCloudHeightRegistrationContainer = 'fullScreenButtonPointCloudHeightRegistrationContainer';
export const threeDContainerPointCloudHeightRegistrationContainer = 'threeDContainerPointCloudHeightRegistrationContainer';
export const topLabelThreeDContainerPointCloudHeightRegistrationContainer = 'topLabelThreeDContainerPointCloudHeightRegistrationContainer';
export const perspectiveLabelThreeDContainerPointCloudHeightRegistrationContainer = 'perspectiveLabelThreeDContainerPointCloudHeightRegistrationContainer';
export const resetRegistrationButtonPointCloudHeightRegistrationContainer = 'resetRegistrationButtonPointCloudHeightRegistrationContainer';
export const nextButtonPointCloudHeightRegistrationContainer = 'nextButtonPointCloudHeightRegistrationContainer';

//Point Cloud Zero Degree Registration Container - Data Center
export const pointCloudZeroDegreeRegistrationContainer = 'pointCloudZeroDegreeRegistrationContainer';
export const headerPointCloudZeroDegreeRegistrationContainer = 'headerPointCloudZeroDegreeRegistrationContainer';
export const instructionsContainerPointCloudZeroDegreeRegistrationContainer = 'instructionsContainerPointCloudZeroDegreeRegistrationContainer';
export const noteHeaderPointCloudZeroDegreeRegistrationContainer = 'noteHeaderPointCloudZeroDegreeRegistrationContainer';
export const noteDescriptionPointCloudZeroDegreeRegistrationContainer = 'noteDescriptionPointCloudZeroDegreeRegistrationContainer';
export const horizontalSliderContainerPointCloudZeroDegreeRegistrationContainer = 'horizontalSliderContainerPointCloudZeroDegreeRegistrationContainer';
export const minusButtonHorizontalSliderContainerPointCloudZeroDegreeRegistrationContainer =
  'minusButtonHorizontalSliderContainerPointCloudZeroDegreeRegistrationContainer';
export const plusButtonHorizontalSliderContainerPointCloudZeroDegreeRegistrationContainer =
  'plusButtonHorizontalSliderContainerPointCloudZeroDegreeRegistrationContainer';
export const verifyRotationCheckBoxPointCloudZeroDegreeRegistrationContainer = 'verifyRotationCheckBoxPointCloudZeroDegreeRegistrationContainer';
export const acceptRotationAndContinueButtonPointCloudZeroDegreeRegistrationContainer =
  'acceptRotationAndContinueButtonPointCloudZeroDegreeRegistrationContainer';

//Point Cloud Throats Container - Data Center
export const pointCloudThroatsContainer = 'pointCloudThroatsContainer';
export const headerPointCloudThroatsContainer = 'headerPointCloudThroatsContainer';
export const instructionsPointCloudThroatsContainer = 'instructionsPointCloudThroatsContainer';
export const fullScreenButtonPointCloudThroatsContainer = 'fullScreenButtonPointCloudThroatsContainer';
export const snorkelOutDraggableIconPointCloudThroatsContainer = 'snorkelOutDraggableIconPointCloudThroatsContainer';
export const snorkelInDraggableIconPointCloudThroatsContainer = 'snorkelInDraggableIconPointCloudThroatsContainer';

//Point Cloud Registration Container - Data Center
export const headerPointCloudRegistrationContainer = 'headerPointCloudRegistrationContainer';
export const primaryDescriptionPointCloudRegistrationContainer = 'primaryDescriptionPointCloudRegistrationContainer';
export const secondaryDescriptionPointCloudRegistrationContainer = 'secondaryDescriptionPointCloudRegistrationContainer';
export const threeDViewHintLabelPointCloudRegistrationContainer = 'threeDViewHintLabelPointCloudRegistrationContainer';
export const pointCloudTypeLabelPointCloudRegistrationContainer = 'pointCloudTypeLabelPointCloudRegistrationContainer';
export const vesselStateLabelPointCloudRegistationContainer = 'vesselStateLabelPointCloudRegistationContainer';
export const vesselNameLabelPointCloudRegistrationContainer = 'vesselNameLabelPointCloudRegistrationContainer';
export const campaignNameLabelPointCloudRegistrationContainer = 'campaignNameLabelPointCloudRegistrationContainer';
export const dateOfMeasurementLabelPointCloudRegistrationContainer = 'dateOfMeasurementLabelPointCloudRegistrationContainer';
export const heatNumberLabelPointCloudRegistrationContainer = 'heatNumberLabelPointCloudRegistrationContainer';

//Point Cloud Scans Data Upload Done Container - Data Center
export const pointCloudScansDataUploadDoneContainer = 'pointCloudScansDataUploadDoneContainer';
export const uploadStatusLabelPointCloudScansDataUploadDoneContainer = 'uploadStatusLabelPointCloudScansDataUploadDoneContainer';
export const vesselNameLockedInputPointCloudScansDataUploadDoneContainer = 'vesselNameLockedInputPointCloudScansDataUploadDoneContainer';
export const campaignNameLockedInputPointCloudScansDataUploadDoneContainer = 'campaignNameLockedInputPointCloudScansDataUploadDoneContainer';
export const heatNumberLockedInputPointCloudScansDataUploadDoneContainer = 'heatNumberLockedInputPointCloudScansDataUploadDoneContainer';
export const dateOfMeasurementLockedInputPointCloudScansDataUploadDoneContainer = 'dateOfMeasurementLockedInputPointCloudScansDataUploadDoneContainer';
export const timeOfMeasurementLockedInputPointCloudScansDataUploadDoneContainer = 'timeOfMeasurementLockedInputPointCloudScansDataUploadDoneContainer';
export const vesselLiningLockedInputPointCloudScansDataUploadDoneContainer = 'vesselLiningLockedInputPointCloudScansDataUploadDoneContainer';
export const liningTemperatureLockedInputPointCloudScansDataUploadDoneContainer = 'liningTemperatureLockedInputPointCloudScansDataUploadDoneContainer';
export const liningConditionLockedInputPointCloudScansDataUploadDoneContainer = 'liningConditionLockedInputPointCloudScansDataUploadDoneContainer';
export const maintenanceTypeLockedInputPointCloudScansDataUploadDoneContainer = 'maintenanceTypeLockedInputPointCloudScansDataUploadDoneContainer';
export const notesLockedInputPointCloudScansDataUploadDoneContainer = 'notesLockedInputPointCloudScansDataUploadDoneContainer';

//Upload Panel - Data Center
export const titleUploadPanel = 'title';
export const expectedFileFormatsDescriptionUploadPanel = 'description';

//Upload Done Container - Data Center
export const uploadDoneContainer = 'uploadDoneContainer';
export const newUploadButtonUploadDoneContainer = 'newUpload';
export const homeButtonUploadDoneContainer = 'homeButton';
export const proceedToMeasurementDataButtonUploadDoneContainer = 'newMeasurementUpload';

// Wear Explorer Strip
export const heatNumberLabelMeasurementStrip = 'heatNumberLabelMeasurementStrip';
export const dateTimeLabelMeasurementStrip = 'dateTimeLabelMeasurementStrip';
export const measurementStripContainerWearExplorer = 'measurementStrip';
export const wearHeaderStripWearExplorer = 'wearHeaderStrip';
export const measurementDataTimestampLabelWearExplorer = 'measurementDataTimestampLabelWearExplorer';
export const processDataTimestampLabelWearExplorer = 'processDataTimestampLabelWearExplorer';
export const criticalHeatLabelHeaderWearExplorer = 'criticalHeatLabelHeaderWearExplorer';
export const criticalAreaLabelHeaderWearExplorer = 'criticalAreaLabelHeaderWearExplorer';

// Wear Explorer
export const wearExplorerContainerWearExplorer = 'wearExplorer';
export const remainingBrickLengthSectionWearExplorer = 'remainingBrickLengthSectionWearExplorer';
export const predictionGraphContainerWearExplorer = 'predictionGraphContainerWearExplorer';
export const areaDisplayNameLabelWearExplorer = 'areaDisplayNameLabelWearExplorer';
export const remainingHeatsValueLabelHeaderWearExplorer = 'remainingHeatsValueLabelHeaderWearExplorer';
export const remainingHeatsValueLabelWearExplorer = 'remainingHeatsValueLabelWearExplorer';
export const criticalHeatLabelWearExplorer = 'criticalHeatLabelWearExplorer';
export const legendLabelWearExplorer = 'legendLabelWearExplorer';
export const minimalLiningThicknessYLabelWearExplorer = 'minimalLiningThicknessYLabelWearExplorer';
export const maximumYLabelWearExplorer = 'maximumYLabelWearExplorer';
export const minimumYLabelWearExplorer = 'minimumYLabelWearExplorer';
export const predictedLifeTimeLabelWearExplorer = 'predictedLifeTimeLabelWearExplorer';
export const predictedLifeTimeXAxisLabelWearExplorer = 'predictedLifeTimeXAxisLabelWearExplorer';
export const predictedLifeTimeCrossPointWearExplorer = 'predictedLifeTimeCrossPointWearExplorer';
export const linearRegressionLabelWearExplorer = 'linearRegressionLabelWearExplorer';
export const linearRegressionCrossPointWearExplorer = 'linearRegressionCrossPointWearExplorer';
export const linearRegressionXAxisLabelWearExplorer = 'linearRegressionXAxisLabelWearExplorer';
export const processDataHeatNumberLabelWearExplorer = 'processDataHeatNumberLabelWearExplorer';
export const measuredHeatNumberLabelWearExplorer = 'measuredHeatNumberLabelWearExplorer';
export const supplierValueLabelWearExplorer = 'supplierValueLabelWearExplorer';
export const resizeRegionSectionButtonWearExplorer = 'resizeRegionSectionButtonWearExplorer';
export const latestTreatmentLabelWearExplorer = 'latestTreatmentLabel';
export const remainingTreatmentsLabelWearExplorer = 'remainingTreatmentsLabel';
export const predictedTreatmentsLabelWearExplorer = 'predictedTreatmentsLabel;';
export const wearPredictionGraphWearExplorer = 'wearPredictionGraphWearExplorer';
export const wearPredictionGraphInfoCardWearExplorer = 'wearPredictionGraphInfoCardWearExplorer';
export const predictionRowWearExplorer = 'predictionRowWearExplorer';
export const linearRegressionRowWearExplorer = 'linearRegressionRowWearExplorer';
export const standardCampaignLabelWearExplorer = 'StandardCampaignLabelWearExplorer';
export const upperLimitRowWearExplorer = 'UpperLimitRowWearExplorer';
export const lowerLimitRowWearExplorer = 'LowerLimitRowWearExplorer';

// Wear Explorer Quick Info Card
export const quickInfoCardContainerWearExplorer = 'quickInfoCardContainerWearExplorer';
export const heatMapWearExplorer = 'heatMapWearExplorer';
export const predictionHeaderLabelWearExplorer = 'predictionHeaderWearExplorer';
export const predictionTextWearExplorer = 'predictionTextWearExplorer';
export const linearRegressionHeaderLabelWearExplorer = 'linearRegressionHeaderWearExplorer';
export const linearRegressionTextWearExplorer = 'linearRegressionTextWearExplorer';
export const lifetimeDifferenceTextWearExplorer = 'lifetimeDifferenceTextWearExplorer';
export const averageWearSpeedValueLabelWearExplorer = 'averageWearSpeedValueLabelWearExplorer';
export const lastMeasurementValueLabelWearExplorer = 'latestMeasurementValueLabelWearExplorer';
export const snorkelTextWearExplorer = 'snorkelTextWearExplorer';

//Wear Explorer Legend
export const legendContainerWearExplorer = 'legendContainerWearExplorer';
export const measuredRLTLabelWearExplorer = 'measuredRLTLabelWearExplorer';
export const predictedCheckboxContainerWearExplorer = 'predictedCheckboxContainerWearExplorer';
export const standardCampaignCheckboxContainerWearExplorer = 'standardCampaignCheckboxContainerWearExplorer';
export const linearRegressionCheckboxContainerWearExplorer = 'linearRegressionCheckboxContainerWearExplorer';

// Wear Explorer Prediction Graph Container
export const predictionLifetimeContainerWearExplorer = 'predictionLifetimeContainerWearExplorer';
export const predictionHistoryHeaderWearExplorer = 'predictionHistoryHeaderWearExplorer';
export const predictionHistoryGraphWearExplorer = 'predictionHistoryGraphWearExplorer';
export const predictionHistoryTooltipWearExplorer = 'predictionHistoryTooltipWearExplorer';

// Wear Explorer Snorkel
export const snorkelSectionWearExplorer = 'snorkelSectionWearExplorer';
export const regionsMapRhWearExplorer = 'regionsMapRhWearExplorer';
export const snorkelGraphContainerWearExplorer = 'snorkelGraphContainerWearExplorer';
export const snorkelImageContainerWearExplorer = 'snorkelImageContainerWearExplorer';
export const snorkelAvgNameLabelWearExplorer = 'snorkelAvgNameLabelWearExplorer';
export const snorkelForecastValueLabelWearExplorer = 'snorkelForecastValueLabelWearExplorer';
export const snorkelLegendLabelWearExplorer = 'snorkelLegendLabelWearExplorer';
export const snorkelCriticalLiningLabelWearExplorer = 'snorkelCriticalLiningLabelWearExplorer';
export const maximumYLabelSnorkelWearExplorer = 'maximumYLabelSnorkelWearExplorer';
export const minimumYLabelSnorkelWearExplorer = 'minimumYLabelSnorkelWearExplorer';
export const currentSnorkelValueWearExplorer = 'currentSnorkelValueWearExplorer';
export const currentSnorkelCriticalValueWearExplorer = 'currentSnorkelCriticalValueWearExplorer';
export const currentSnorkelHeatWearExplorer = 'currentSnorkelHeatWearExplorer';
export const currentSnorkelCriticalHeatWearExplorer = 'currentSnorkelCriticalHeatWearExplorer';
export const predictedCriticalSnorkelHeatWearExplorer = 'predictedCriticalSnorkelHeatWearExplorer';

//Measurement View
export const measurementViewContainerMeasurementView = 'measurementViewWrapper';
export const noMeasurementsContainerMeasurementView = 'measurementViewNoMeasurementPage';
export const headerStripMeasurementView = 'measurementViewHeaderStrip';
export const measurementsDropdownMeasurementView = 'measurementViewMeasurementDropdown';
export const vesselStateFiltersDropdownMeasurementView = 'measurementViewVesselStateFilterDropdown';
export const vesselStateFiltersDropdownOptionsMeasurementView = 'measurementViewVesselStateFilterDropdownOption';
export const dateFilterInputMeasurementView = 'measurementViewDateFilter';
export const closeButtonCalendarMeasurementView = 'measurementViewMeasurementDropdownDateFilterCloseButton';
export const allMeasurementsFilteredOutLabelMeasurementView = 'allMeasurementsFilteredOutLabelMeasurementView';
export const errorContainerMeasurementView = 'measurementViewErrorPage';
export const toggleButtonMeasurementView = 'toggleButtonMeasurementView';

// Measurement View - Volume Information Panel
export const volumePanelContainerMeasurementView = 'volumePanelContainerMeasurementView';
export const volumeLabelVolumeInfoPanel = 'volumeLabelVolumeInfoPanel';
export const metadataContainerVolumeInfoPanel = 'metadataContainerVolumeInfoPanel';
export const volumeInfoLabelVolumeInfoPanel = 'heatNumberVolumeInfoPanel';
export const volumeValueVolumeInfoPanel = 'volumeValueVolumeInfoPanel';
export const unitLabelVolumeInfoPanel = 'unitLabelVolumeInfoPanel';
export const volumeGraphVolumeInfoPanel = 'volumeGraphVolumeInfoPanel';
export const quickCompareContainerVolumeInfoPanel = 'quickCompareContainerVolumeInfoPanel';
export const quickCompareLabelVolumeInfoPanel = 'quickCompareLabelVolumeInfoPanel';
export const referenceHeatLabelVolumeInfoPanel = 'referenceHeatLabelVolumeInfoPanel';
export const referenceVolumeInfoContainerLabelVolumeInfoPanel = 'referenceVolumeInfoContainerLabelVolumeInfoPanel';
export const deltaVolumeInfoContainerVolumeInfoPanel = 'deltaVolumeInfoContainerVolumeInfoPanel';

//Measurement View/ Volume Explorer dropdown options
export const measurementsDropdownOptions = 'measurementViewMeasurementDropdownOption';
export const heatValueMeasurementDropdownOption = 'heatValueMeasurementDropdownOption';
export const dateMeasurementDropdownOption = 'dateMeasurementDropdownOption';

//Operator Display
export const operatorDisplayContainerOperatorDisplay = 'operatorDisplay';
export const allRegionsContainerOperatorDisplay = 'allRegionsContainer';
export const maintenanceBoxContainerOperatorDisplay = 'maintenance-box';
export const criticalBoxContainerOperatorDisplay = 'criticalBox';
export const topStripContainerOperatorDisplay = 'wmsHeaderStrip';
export const criticalAreaContainerOperatorDisplay = 'criticalAreaContainer';
export const avgSpeedLabelOperatorDisplay = 'avgWearSpeed';
export const avgMeasurementGapLabelOperatorDisplay = 'avgMeasurementGap';
export const fullScreenButtonOperatorDisplay = 'fullScreenButton';
export const gearButtonOperatorDisplay = 'gearButton';
export const minValueLabelOperatorDisplay = 'minValue';
export const targetValueLabelOperatorDisplay = 'targetValue';
export const deltaValueLabelOperatorDisplay = 'deltaValue';
export const wearValueLabelOperatorDisplay = 'wearValue';
export const graphCriticalAreaContainerOperatorDisplay = 'test-critical-area';
export const zoomedGraphCriticalAreaContainerOperatorDisplay = 'zoomed-test-critical-area';
export const currentHeatMainGraphLabelOperatorDisplay = 'currentHeatMainGraph';
export const zoomedGraphOperatorDisplay = 'zoomedGraph';
export const compareToggleOperatorDisplay = 'compareToggle';
export const graphViewButtonOperatorDisplay = 'graphViewButton';
export const tableViewButtonOperatorDisplay = 'tableViewButton';
export const legendContainerOperatorDisplay = 'legendContainer';
export const firstCampaignLabelOperatorDisplay = 'firstCampaignLegendLabel';
export const secondCampaignLabelOperatorDisplay = 'secondCampaignLegendLabel';
export const supplierLabelOperatorDisplay = 'supplierLabel';
export const regionHeaderValuesContainerOperatorDisplay = 'regionHeaderValues';
export const mainGraphOperatorDisplay = 'mainGraphOperatorDisplay';
export const regionTitleOperatorDisplay = 'regionTitle';
export const tableContainerOperatorDisplay = 'tableContainerOperatorDisplay';

//Campaign Dropdown
export const campaignDropdown = 'campaignsDropdown';
export const campaignsDropdownItem = 'campaignsDropdownItem';

//Vessel Dropdown
export const vesselsDropdown = 'vesselsDropdown';
export const vesselsDropdownItem = 'vesselsDropdownItem';

//ARO & Measurement View Item Navigation
export const previousItemButtonNavigation = 'previousItemButtonNavigation';
export const followingItemButtonNavigation = 'followingItemButtonNavigation';
export const lastItemButtonNavigation = 'lastItemButtonNavigation';

//Region Selection Container - OperatorDisplay
export const regionSelectionContainerOperatorDisplay = 'regionSelectionContainer';
export const titleRegionSelectionContainer = 'regionSelectionTitle';
export const titleDescriptionRegionSelectionContainer = 'regionSelectionDescriptionTitle';
export const closeButtonRegionSelectionContainer = 'regionSelectionXIcon';
export const descriptionRegionSelectionContainer = 'regionSelectionDescription';
export const cancelButtonRegionSelectionContainer = 'regionSelectionCancelButton';
export const applyChangesButtonRegionSelectionContainer = 'regionSelectionApplyButton';
export const regionItemRegionSelectionContainer = 'regionItem';

//Legal Information
export const headerImprint = 'imprintHeader';
export const headerPrivacy = 'privacyHeading';
export const headerTermsOfUse = 'termsOfUseHeading';

//GOB Report
export const rhGOBStripContainerRHPrediction = 'rhGobreport';
export const rhPredictionContainerRHPrediction = 'rhPredictionContainer';
export const rhPredictionTitleRHPrediction = 'rhPredictionTitle';
export const rhPredictionTableContainerRHPrediction = 'rhPredictionContent';

//ARO Report
export const reportContainerAroReport = 'reportContainerAroReport';
export const reportHeaderStripAroReport = 'reportHeaderStripAroReport';
export const headerComponentAroReport = 'headerComponentAroReport';
export const reportDropdownAroReport = 'reportDropdownAroReport';
export const reportDropdownOptionAroReport = 'aroReportReportDropdownOption';
export const zoneComponentAroReport = 'zoneComponentAroReport';
export const zoneHeaderAroReport = 'zoneHeaderAroReport';
export const kilnZoneSmallComponentAroReport = 'kilnZoneSmallComponentAroReport';
export const kilnZoneLargeComponentAroReport = 'kilnZoneLargeComponentAroReport';
export const chartWrapperAroReport = 'chartWrapperAroReport';
export const chartLegendsAroReport = 'chartLegendsAroReport';
export const chartThresholdLabelAroReport = 'chartThresholdLabelAroReport';
export const chartRmseLabelAroReport = 'chartRmseLabelAroReport';
export const chartXDayLabelAroReport = 'chartXDayLabelAroReport';
export const errorPageAroReport = 'errorPageAroReport';
export const noReportPageAroReport = 'noReportPageAroReport';

//ParameterComparison
export const campaignCompareIconParametersComparison = 'campaignCompareIcon';
export const headerParametersComparison = 'parametersComparisonHeader';
export const wrapperParametersComparison = 'parametersComparisonWrapper';
export const expandAllButtonParametersComparison = 'expandAllButtonParametersComparison';
export const collapseAllButtonParametersComparison = 'collapseAllButtonParametersComparison';
export const parameterItemParametersComparison = 'parameterItemParametersComparison';
export const chartContainerParametersComparison = 'chartContainerParametersComparison';
export const trimToggleParametersComparison = 'trimToggleParametersComparison';
export const wearParameterChartParametersComparison = 'wearParameterChartParametersComparison';
//export const parameterLegendParametersComparison = 'parameterLegend';
export const legendLabelParametersComparison = 'parameterLegend';
export const legendFirstCampaignLabelParametersComparison = 'parameterLegend';
export const legendSecondCampaignLabelParametersComparison = 'parameterLegend';

export const parameterNameAndUnitLabelParametersComparison = 'parameterNameAndUnitLabel';
export const upperOuterLimitLabelParametersComparison = 'upperOuterLimitLabel';
export const lowerOuterLimitLabelParametersComparison = 'lowerOuterLimitLabel';
export const lowerInnerLimitLabelParametersComparison = 'lowerInnerLimitLabel';
export const upperInnerLimitLabelParametersComparison = 'upperInnerLimitLabel';
export const medianLabelParametersComparison = 'medianLabel';

export const chartTooltipContainerParametersComparison = 'chartTooltipContainer';
export const headerChartTooltipParametersComparison = 'headerChartTooltip';
export const currentCampaignHeatChartTooltipParametersComparison = 'currentCampaignHeatChartTooltip';
export const previousCampaignHeatChartTooltipParametersComparison = 'previousCampaignHeatChartTooltip';

export const violinChartParametersComparison = 'violinChartParametersComparison';
export const violinChartFirstCampaignParametersComparison = 'violinChartFirstCampaign';
export const violinChartSecondCampaignParametersComparison = 'violinChartSecondCampaign';
export const violinChartFooterFirstCampaignLabelParametersComparison = 'violinChartFooterFirstCampaign';
export const violinChartFooterSecondCampaignLabelParametersComparison = 'violinChartFooterSecondCampaign';
export const violinChartTooltipParametersComparison = 'violinChartTooltip';
export const parameterNameViolinTooltipParametersComparison = 'parameterNameViolinTooltip';
export const firstCampaignViolinTooltipParametersComparison = 'firstCampaignViolinTooltip';
export const maximumRowFirstCampaignViolinTooltipParametersComparison = 'maximumRowFirstCampaignViolinTooltip';
export const medianRowFirstCampaignViolinTooltipParametersComparison = 'medianRowFirstCampaignViolinTooltip';
export const minimumRowFirstCampaignViolinTooltipParametersComparison = 'minimumRowFirstCampaignViolinTooltip';
export const averageRowFirstCampaignViolinTooltipParametersComparison = 'averageRowFirstCampaignViolinTooltip';
export const secondCampaignViolinTooltipParametersComparison = 'secondCampaignViolinTooltip';
export const maximumRowSecondCampaignViolinTooltipParametersComparison = 'maximumRowSecondCampaignViolinTooltip';
export const medianRowSecondCampaignViolinTooltipParametersComparison = 'medianRowSecondCampaignViolinTooltip';
export const minimumRowSecondCampaignViolinTooltipParametersComparison = 'minimumRowSecondCampaignViolinTooltip';
export const averageRowSecondCampaignViolinTooltipParametersComparison = 'averageRowSecondCampaignViolinTooltip';

//Measurement Data
export const headerMeasurementData = 'headerMeasurementData';
export const addNewMeasurementButtonMeasurementData = 'addNewMeasurementButton';
export const tableContainerMeasurementData = 'tableContainerMeasurementData';
export const downloadButtonMeasurementData = 'downloadButtonMeasurementData';
export const deleteButtonMeasurementData = 'deleteButtonMeasurementData';
export const previousButtonMeasurementData = 'previousButtonMeasurementData';
export const nextButtonMeasurementData = 'nextButtonMeasurementData';
export const currentPageNumberInputMeasurementData = 'currentPageNumberInputMeasurementData';

//Les Report Strip
export const scansDropdown = 'scansDropdown';
export const scanDropdownItem = 'scanDropdownItem';

//Les Report
export const headerLes = 'headerLes';
export const infoPanelContainerLes = 'infoPanelContainerLes';
export const scanInfoHeaderLes = 'scanInfoHeaderLes';
export const extractDataButtonLes = 'extractDataButtonLes';
export const extractionContainerLes = 'extractionContainerLes';
export const publishButtonLes = 'publishButtonLes';
export const kilnSchematicWrapperLes = 'kilnSchematicWrapperLes';
export const liningChartWrapperLes = 'liningChartWrapperLes';
export const thicknessSliderWrapperLes = 'thicknessSliderWrapperLes';
export const colorGraphWrapperLes = 'colorGraphWrapperLes';
export const liningSliceWrapperLes = 'liningSliceWrapperLes';

//Lining Strip tooltip
export const brickNameLiningStripTooltip = 'brickNameLiningStripTooltip';
export const elapsedTimeLiningStripTooltip = 'elapsedTimeLiningStripTooltip';
export const elapsedTimeFromToLiningStripTooltip = 'elapsedTimeFromToLiningStripTooltip';

//Graph tooltip
export const thicknessGraphTooltip = 'thicknessGraphTooltip';
export const brickNameGraphTooltip = 'brickNameGraphTooltip';
export const installationDateGraphTooltip = 'installationDateGraphTooltip';
export const elapsedTimeGraphTooltip = 'elapsedTimeGraphTooltip';

//Volume Explorer
export const comparisonPanelContainerVolumeExplorer = 'comparisonPanelContainerVolumeExplorer';
export const headerVolumeExplorer = 'headerVolumeExplorer';
export const comparisonLabelVolumeExplorer = 'comparisonLabelVolumeExplorer';
export const comparisonValueWithUnitVolumeExplorer = 'comparisonValueWithUnitVolumeExplorer';
export const initialContainerVolumeExplorer = 'initialContainerVolumeExplorer';
export const currentContainerVolumeExplorer = 'currentContainerVolumeExplorer';
export const compareToContainerVolumeExplorer = 'compareToContainerVolumeExplorer';
export const rateCalculationContainerVolumeExplorer = 'rateCalculationContainerVolumeExplorer';
export const heatDropdownVolumeExplorer = 'heatDropdownVolumeExplorer';
export const heatDropdownOptionsContainerVolumeExplorer = 'compareToDropdownOptionsContainerVolumeExplorer';
export const shortcutLabelVolumeExplorer = 'shortcutLabelVolumeExplorer';
export const heatLabelVolumeExplorer = 'heatLabelVolumeExplorer';
export const volumeStartImageVolumeExplorer = 'volumeStartImageVolumeExplorer';
export const volumeStartLabelVolumeExplorer = 'volumeStartLabelVolumeExplorer';
export const heatLabelStripVolumeExplorer = 'heatLabelStripVolumeExplorer';
export const dateLabelStripVolumeExplorer = 'dateLabelStripVolumeExplorer';
export const graphMainContainerVolumeExplorer = 'graphMainContainerVolumeExplorer';
export const graphContainerVolumeExplorer = 'graphContainerVolumeExplorer';
export const graphNameLabelVolumeExplorer = 'graphNameLabelVolumeExplorer';
