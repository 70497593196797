import { i18nReact, Trans } from '@rhim/i18n';
import { AppStateAlertIcon } from '@rhim/icons/64';
import { Hyperlink, MaintenancePanel } from '@rhim/react';
import React from 'react';
import styled from 'styled-components';

import AppContext from '../app/AppContext';
import useShortHashedSelectedCustomer from '../hooks/useShortHashedSelectedCustomer';
import { ROUTES } from '../utilities';

const PageRequiresAPO: React.ChildlessComponent = () => {
  const { t } = i18nReact.useTranslation(['app']);
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;

  // eslint-disable-next-line compat/compat

  useShortHashedSelectedCustomer(customerId, ROUTES.DISPLAY);

  // Persisted vessel ID
  return (
    <SWrapper>
      <MaintenancePanel
        size="large"
        heading={t('app:customerFeatures.apo.heading')}
        subHeading={
          <Trans t={t} i18nKey="app:customerFeatures.apo.subHeading">
            <span>
              Please reach out to <Hyperlink href="mailto:romy-sophie.katz@rhimagnesita.com">romy-sophie.katz@rhimagnesita.com</Hyperlink> for purchasing.
            </span>
          </Trans>
        }
        icon={<AppStateAlertIcon />}
      />
    </SWrapper>
  );
};

const SWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default React.memo(PageRequiresAPO);
