import { Trans } from '@rhim/i18n';
import { Heading, Hyperlink, Legal } from '@rhim/react';
import { headerPrivacy } from '@rhim/test-ids';
import { FC } from 'react';

const LegalTabPrivacy: FC<React.PropsWithChildren> = () => {
  return (
    <Trans i18nKey="static:legal.topics.privacy.content">
      <Heading data-test-id={headerPrivacy} level="h3">
        Privacy Statement
      </Heading>
      <Legal.Paragraph>
        As part of the services offered on the online portal(s) (“Website”, “Webpage” or “Webportal”), RHI Magnesita GmbH, Kranichberggasse 6, 1120 Vienna,
        Austria (“RHI Magnesita” or “we”), will process a limited amount of your personal data.
      </Legal.Paragraph>
      <Legal.Paragraph>
        This process is in line with the EU General Data Protection Regulation 2016/679 (“GDPR”) and other relevant national data protection laws. You shall
        confirm that you have read and accepted this Privacy Statement (“Statement”) before accessing the Webpage. The Webpage may be offered in both mobile and
        desktop versions.
      </Legal.Paragraph>
      <Heading level="h4">Data Controller</Heading>
      <Legal.Paragraph>
        The data controller of the Webpage is RHI Magnesita. Kindly note that RHI Magnesita is part of a group of companies (companies that own or control us,
        or that are controlled by us). The group companies may offer their services through the Webpage. In case you request or obtain their services, the
        relevant group company may also act as data controller.
      </Legal.Paragraph>
      <Heading level="h4">Language</Heading>
      <Legal.Paragraph>
        This Statement and the Webpage are offered in English and, based on our discretion or local legal requirements, in other languages. The English version
        shall prevail, unless otherwise stated.
      </Legal.Paragraph>
      <Heading level="h4">Legal bases and processing purposes</Heading>
      <Legal.Paragraph>
        The processing of your personal data is necessary for the performance of the services offered on the Webpage. In addition, your data may be processed
        based on your consent, to fulfill our legal and regulatory obligations or based on our legitimate interests, as described in this Statement.
      </Legal.Paragraph>
      <Legal.Paragraph>
        To this end, we shall set up your account on this Webpage, and offer our services which include but are not limited to sharing our products’
        documentation, enable you to place orders, access quotes and invoices, raise issues and track support tickets, view shipments, dashboards and reports,
        complete surveys and exchange communication with us. In addition to optimizing the quality of the Webpage services, we will segment your activities and
        may deliver tailored content and offers. The Webpage may also offer the possibility to access applications or products of third parties with whom we
        conduct joint business activities.
      </Legal.Paragraph>
      <Heading level="h4">Processed personal data</Heading>
      <Legal.Paragraph>The following categories of personal data shall be processed on the Webpage:</Legal.Paragraph>
      <Legal.Table>
        <tbody>
          <tr>
            <Legal.Cell>Account data</Legal.Cell>
            <Legal.Cell> name and surname, work email address, work phone number, work position (department) </Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>Company data</Legal.Cell>
            <Legal.Cell>company name, office address, billing address, phone number, etc.</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>Account IT data</Legal.Cell>
            <Legal.Cell>status, IP address, logs, operating system, etc.</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>Account activities</Legal.Cell>
            <Legal.Cell>placed orders, invoices, quotes, uploaded documents and data, tickets, subscriptions, surveys, account segmentation, etc.</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>Written communication</Legal.Cell>
            <Legal.Cell>
              emails or text messages including in chat format or similar, (and possible attachments) sent to us via the contact channels provided on Webpage.
            </Legal.Cell>
          </tr>
        </tbody>
      </Legal.Table>
      <Legal.Paragraph>The dedicated Cookie Statement describes the data that may be collected via cookies on the Webpage.</Legal.Paragraph>

      <Heading level="h4">Security Measures</Heading>
      <Legal.Paragraph>
        We have adopted the due technical and organization measures (TOMs) to protect the Webpage and your data. For instance, data are processed in strict
        confidence and, where applicable, in pseudonymized format, access is granted only on a need-to-know basis and to persons that are subject to
        confidentiality agreements. We share the data with external third parties only if this is necessary to provide the Webpage services or is required by
        applicable laws. We do not sell your personal data.
      </Legal.Paragraph>
      <Heading level="h4">Third parties and place of data processing</Heading>
      <Legal.Paragraph>
        The personal data may be shared in full or in part with the staff of our group companies. Typically, such access is granted to staff that is responsible
        for sales, customer care, offered products (including their technical aspects), support and security. Your data may also be accessed by our
        sub-processor(s) contracted to maintain and operate the Webpage. Your data may be also shared with governmental and regulatory bodies if required by
        applicable laws.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The Webpage may offer optional applications of third parties with whom we conduct business. If you choose to utilize their services, then some of your
        data may be shared with these third parties. In this case, the privacy terms of these parties may apply to you as described on the Webpage.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Your personal data will be primarily processed in the EU/EEA counties, where our data centers are located. However, we use third party service providers
        to operate the Webpage and in exceptional cases they may access your personal data outside the EU/EEA, for instance by Microsoft (Azure) global support
        services. Your data may be also accessed by RHI Magnesita staff located in a country outside the EU/EEA to provide you with the necessary services, such
        as handling technical issues. If required by applicable national laws, the data may be stored in full or in part in your country of residence.
      </Legal.Paragraph>
      <Legal.Paragraph>
        The processing of personal data outside the EU/EEA in countries that are not subject to adequacy decisions of the EU is properly protected, for instance
        by the EU-U.S. Privacy Shield, EU Standard Contractual Clauses or other valid measures
      </Legal.Paragraph>
      <Heading level="h4">Automated decision making</Heading>
      <Legal.Paragraph>
        The Webpage does not make any fully automated decision on you, including profiling, and human intervention is involved in all cases. Should this change,
        we shall inform you accordingly.
      </Legal.Paragraph>
      <Heading level="h4">Storage period of the data</Heading>
      <Legal.Paragraph>
        If you have simply created an account and not performed any activities on the Webpage, your data shall be deleted (or fully anonymized) 30 days after
        your request for closure of the account.
      </Legal.Paragraph>
      <Legal.Paragraph>
        If you have performed activities on the Webpage, for instance placed orders, shared documents or sent written queries etc., as a rule, your data are
        retained for 3 years following the closure of your account. This is based on our legitimate interest and general retention periods permitted by law for
        this type of services. After this term some data may be retained for longer periods in fully anonymized and aggregated format.
      </Legal.Paragraph>
      <Legal.Paragraph>
        Your personal data may be retained for longer periods if required to comply applicable laws (e.g. national financial laws) or to defend our legal
        interests. These terms shall be defined on a case by case bases
      </Legal.Paragraph>
      <Legal.Paragraph>
        If you like to close your account, you may contact us via the provided contacts on Webpage. Kindly note that we reserve the right to close your account
        at any time at our own discretion. The above retention terms shall apply also in this case.
      </Legal.Paragraph>
      <Heading level="h4">Your rights</Heading>
      <Legal.Paragraph>
        You have the right to request at any time whether and which data we process about you on the Webpage. Please note that information is mostly and already
        available on your account.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You also have the right to request deletion of the data, restriction of processing, data transferability and objection to processing. These requests are
        subject to retention periods and other applicable rules.
      </Legal.Paragraph>
      <Legal.Paragraph>
        You also have the option of withdrawing your consent, if processing is based on consent, however the lawfulness of the data processing up to the time of
        the revocation is not affected. In most cases, you have the option to withdraw your consent on the Website, e.g. via opt-out or unsubscribe functions.
      </Legal.Paragraph>
      <Legal.Paragraph>
        In the above cases and if you have any question on data protection, please contact us via the provided contacts on Webpage or
        <Hyperlink href="mailto:dataprivacy@RHIMagnesita.com" text="dataprivacy@RHIMagnesita.com" />
      </Legal.Paragraph>
      <Legal.Paragraph>
        If you are of the opinion that we have violated your privacy rights in any way, you may complain with the Austrian Data Protection Authority or the data
        protection authority of your country of residence. In such event, we kindly ask you to contact us first and we shall endeavor to handle your concerns
        with due care.
      </Legal.Paragraph>
      <Legal.Paragraph>Any changes to this Statement shall be published on the Webpage.</Legal.Paragraph>
      <Heading level="h4">Cookies Statement</Heading>
      <Legal.Paragraph>
        We use cookies to make our portal(s) services (“Website”, “Webpage” or “Webportal”) more user-friendly, effective and secure. Cookies are small text
        files that a webserver sends to your internet browser or device when you visit a webpage. As a rule, cookies do not contain personal data and are not
        merged with such type of data. They mainly serve for statistical analyses. Some cookies are deleted automatically when you close the browser. Other
        cookies remain stored on your device and are deleted after a certain period, which depends on the cookie type or your settings. You may change the
        cookies settings in the help function of your browser, the device privacy settings, or dedicated cookies settings toolbar (if available). Note that if
        you choose to deny some cookies, you will not be able to use the Webpage functions and services to the full extent.
      </Legal.Paragraph>
      <Heading level="h4">Strictly Necessary Cookies</Heading>
      <Legal.Paragraph>
        These cookies are necessary for the Webpage to function and cannot be switched off. They are used based on your actions, for instance to set your
        privacy preferences, logins, access forms, ensure security, etc. You may block these cookies via your browser, but in this case some parts of the
        Webpage will not work. These cookies do not store personal data.
      </Legal.Paragraph>
      <Heading level="h4">Performance/Analytics Cookies</Heading>
      <Legal.Paragraph>
        These cookies allow us to monitor the users’ activities on the Webpage for the purpose of improving its performance. For instance, we can identify the
        most used functions or the movements on the Webpage. This information is usually anonymous but in some cases it may be assigned to specific users and
        therefore become identifiable. If you reject these cookies, we cannot monitor your activities and further improve our Webpage.
      </Legal.Paragraph>
      <Heading level="h4">Functional Cookies</Heading>
      <Legal.Paragraph>
        These cookies allow offering enhanced user customization, such as remembering your Webpage preferences or sharing videos. They may be set by us or third
        parties whose services are provided on the Webpage. If you do not allow these cookies, some of these services may not be available.
      </Legal.Paragraph>
      <Heading level="h4">Marketing/Targeting Cookies</Heading>
      <Legal.Paragraph>
        These cookies are set through the Webpage by our advertising partners. They are used by them to profile your preferences and show relevant adverts.
        These cookies can uniquely identify your browser or device, hence indirectly identify you. If you reject these cookies, you will receive less targeted
        advertising.
      </Legal.Paragraph>
      <Legal.Table>
        <thead>
          <tr>
            <Legal.Cell as="th">Webpage</Legal.Cell>
            <Legal.Cell as="th">Cookie Type</Legal.Cell>
            <Legal.Cell as="th">Name</Legal.Cell>
            <Legal.Cell as="th">Lifespan</Legal.Cell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
            <Legal.Cell>.AspNetCore.CookiesC1</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
            <Legal.Cell>.AspNetCore.CookiesC1</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
            <Legal.Cell>ARRAffinity</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>First party</Legal.Cell>
            <Legal.Cell>sfbShellAppVersion</Legal.Cell>
            <Legal.Cell>2 Months</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>First party</Legal.Cell>
            <Legal.Cell>ai_user</Legal.Cell>
            <Legal.Cell>1 Year</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
            <Legal.Cell>.AspNetCore.Cookies</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>First party</Legal.Cell>
            <Legal.Cell>clientchoice</Legal.Cell>
            <Legal.Cell>2 Months</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
            <Legal.Cell>ai_session</Legal.Cell>
            <Legal.Cell>Session</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>First party</Legal.Cell>
            <Legal.Cell>sfbShellAppLaunched</Legal.Cell>
            <Legal.Cell>2 Months</Legal.Cell>
          </tr>
          <tr>
            <Legal.Cell>apo.rhimagnesita.com</Legal.Cell>
            <Legal.Cell>First party</Legal.Cell>
            <Legal.Cell>cookie-policy</Legal.Cell>
            <Legal.Cell>session</Legal.Cell>
          </tr>
        </tbody>
      </Legal.Table>
    </Trans>
  );
};

export default LegalTabPrivacy;
