import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
}

export const AlarmErrorIndicatorIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 15H1L8 1l7 14z" fill={fill} stroke={fill} strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M15 15.5a.5.5 0 0 0 .447-.724l-7-14a.5.5 0 0 0-.894 0l-7 14A.5.5 0 0 0 1 15.5h14z"
        stroke={settings.colors.Operational.State_Alert_Red_4}
        strokeLinejoin="round"
      />
    </svg>
  );
};

AlarmErrorIndicatorIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Operational.State_Alert_Red_3,
};
