import { useMemoCompare } from '@rhim/react';
import { RHIMMeasurementServiceV1ModelsCampaignDto } from '@rhim/rest/measurementService';
import { hasElements, head, isDefined } from '@rhim/utils';
import { isEqual, join } from 'lodash';
import React from 'react';
import useSessionStorageState from 'use-session-storage-state';

import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

function useSelectedCampaigns(initialState: Double<number>) {
  return useSessionStorageState(join([LOCAL_STORAGE_PREFIX_APO, 'selectedCampaignsNr'], ''), {
    defaultValue: initialState,
  });
}

export const CAMPAIGN_NR_NOT_SET = -1;

const initialState: Double<number> = [CAMPAIGN_NR_NOT_SET, CAMPAIGN_NR_NOT_SET];

export function useSelectedComparedCampaigns(
  campaigns?: RHIMMeasurementServiceV1ModelsCampaignDto[],
  comparedCampaigns?: RHIMMeasurementServiceV1ModelsCampaignDto[],
  sameVessel?: boolean
): [Double<number>, React.Dispatch<React.SetStateAction<Double<number>>>] {
  const [persistedCampaigns, setPersistedCampaigns] = useSelectedCampaigns(initialState);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getNextCorrectValue = React.useCallback(
    (
      persistedCampaigns: Double<number>,
      campaigns?: RHIMMeasurementServiceV1ModelsCampaignDto[],
      comparedCampaigns?: RHIMMeasurementServiceV1ModelsCampaignDto[]
    ): Double<number> => {
      if (
        !isDefined(campaigns) ||
        !isDefined(comparedCampaigns) ||
        (isDefined(campaigns) && !hasElements(campaigns) && isDefined(comparedCampaigns) && !hasElements(comparedCampaigns))
      ) {
        // If no campaigns are available, return initial state
        return initialState;
      } else {
        const [firstCampaign, secondCampaign] = persistedCampaigns;

        // Check if persisted (preferred) campaigns are still eligible for comparison
        // They might not be eligible if the user has changed the vessel or the campaigns have been deleted in the meantime
        const persisted1stCampaign = campaigns.find((campaign) => campaign.id === firstCampaign);
        const persisted2ndCampaign = comparedCampaigns.find((campaign) => campaign.id === secondCampaign);

        const is1stPersistedCampaignValid = isDefined(persisted1stCampaign);
        const is2ndPersistedCampaignValid = isDefined(persisted2ndCampaign);

        if (is1stPersistedCampaignValid && is2ndPersistedCampaignValid) {
          // Do nothing. Persisted campaigns are still valid
          return persistedCampaigns;
        } else if (is1stPersistedCampaignValid && !is2ndPersistedCampaignValid) {
          return [persisted1stCampaign.id, hasElements(comparedCampaigns) ? head(comparedCampaigns).id : CAMPAIGN_NR_NOT_SET];
        } else if (is2ndPersistedCampaignValid && !is1stPersistedCampaignValid) {
          return [hasElements(campaigns) ? head(campaigns).id : CAMPAIGN_NR_NOT_SET, persisted2ndCampaign.id];
        } else if (hasElements(campaigns) || hasElements(comparedCampaigns)) {
          const firstAvailableCampaign = hasElements(campaigns) ? head(campaigns).id : CAMPAIGN_NR_NOT_SET;
          const firstAvailableComparedCampaign = hasElements(comparedCampaigns) ? head(comparedCampaigns).id : CAMPAIGN_NR_NOT_SET;
          const secondAvailableCampaign =
            sameVessel === true ? (isDefined(campaigns[1]) ? campaigns[1]?.id : CAMPAIGN_NR_NOT_SET) : firstAvailableComparedCampaign;

          return [firstAvailableCampaign, secondAvailableCampaign];
        } else {
          return initialState;
        }
      }
    },
    [sameVessel]
  );

  const currentCampaigns: Double<number> = React.useMemo(() => {
    return getNextCorrectValue(persistedCampaigns, campaigns, comparedCampaigns);
  }, [getNextCorrectValue, persistedCampaigns, campaigns, comparedCampaigns]);

  React.useDebugValue(currentCampaigns, (value) => `Currently compared campaigns: [${value[0]} ${value[1]}]`);

  React.useEffect(() => {
    const nextCampaigns = getNextCorrectValue(persistedCampaigns, campaigns, comparedCampaigns);

    if (!isEqual(nextCampaigns, persistedCampaigns)) {
      setPersistedCampaigns(nextCampaigns);
    }
  }, [persistedCampaigns, setPersistedCampaigns, campaigns, getNextCorrectValue, comparedCampaigns]);

  const memoizedValue: [Double<number>, React.Dispatch<React.SetStateAction<Double<number>>>] = useMemoCompare(
    [currentCampaigns, setPersistedCampaigns],
    (previous, next) => {
      return next[0] === previous[0] && next[1] === previous[1];
    }
  );

  return memoizedValue;
}
