import { settings } from '@rhim/design';
import { ErrorIcon as ErrorSVG, RedoIcon as RedoSVG } from '@rhim/icons/24';
import { retryButtonProcessDataUploadContainer } from '@rhim/test-ids';
import * as React from 'react';
import styled from 'styled-components';

const ErrorIcon = <ErrorSVG fill={settings.colors.Operational.State_Notif_Magenta_2} />;
const RedoIcon = <RedoSVG fill={settings.colors.Primary.Blue_9} />;

interface Props {
  errorMessage: string[] | React.ReactElement;
  onRetry: React.UIEventHandler<HTMLDivElement>;
}

export const Failure: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ errorMessage, onRetry }) => {
  return (
    <Wrapper>
      <Centerer>
        <IconContainer>{ErrorIcon}</IconContainer>
      </Centerer>
      <Verdict>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Verdict>
      <Centerer>
        <Delete onClick={onRetry} role="button" data-test-id={retryButtonProcessDataUploadContainer}>
          <Target>{RedoIcon}</Target>
        </Delete>
      </Centerer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${settings.colors.Operational.State_Alert_Red_4};
`;

const Centerer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Verdict = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
  flex-grow: 1;
`;

const ErrorMessage = styled.span`
  color: ${settings.colors.Operational.State_Notif_Magenta_2};
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

const Delete = styled.div`
  height: 100%;
  width: 72px;
  display: flex;
  border-left: 1px solid ${settings.colors.Primary.Grey_2};
  justify-content: center;
  align-items: center;
`;

const Target = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
