import '@babylonjs/loaders/glTF';

import type { AbstractMesh, Mesh, Scene } from '@babylonjs/core';
import { AssetsManager, PBRMaterial, SceneLoader, WebRequest } from '@babylonjs/core';
import { CustomMaterial } from '@babylonjs/materials';
import { AuthClient } from '@rhim/react';
import { isDefined } from '@rhim/utils';
import { BearerAccessToken } from 'typings';

import { cleanUpGLTFAssets } from './meshUtils';
import { MESH_CONTAINER_NAME } from './Scene3d/types';

export const customMaterialName = 'customMaterial'; // identifier for our custom material

/**
 * Gets the shared custom material
 * @param scene the scene from which to get the material
 */
export function getCustomMaterial(scene: Scene): CustomMaterial | null {
  const material = scene.getMaterialByName(customMaterialName);
  return material as CustomMaterial | null;
}

/**
 * Removes all meshes amnd materials from the Babylon scene
 * @param scene Babylon scene
 */
export function clearScene(scene: Scene): void {
  const ladleContainer = scene.getNodeByName(MESH_CONTAINER_NAME);
  ladleContainer?.getChildMeshes().forEach((mesh) => {
    mesh.dispose(true, false);
    scene.removeMesh(mesh);
  });
}

let assetsManager: AssetsManager; // singleton AssetsManager
/**
 *  load meshes into babbabylon using a mesh task
 * @param scene babylon scene to attach the assets manager to
 */
export function getAssetsManager(scene: Scene): AssetsManager {
  if (!isDefined(assetsManager)) {
    assetsManager = new AssetsManager(scene);
    assetsManager.useDefaultLoadingScreen = false;
    return assetsManager;
  }
  return assetsManager;
}
/**
 * Adds a hidden (disabled) mesh into the scene, used in the preloading flow
 * @param scene
 * @param measurementId
 * @param modelName name of the model ( file )
 */
export function addDisabledMeshToScenePromise(scene: Scene, measurementId: string, modelName: string) {
  return new Promise<Mesh>((resolve) => {
    const assetsManager = getAssetsManager(scene);
    const path = `/api/measurement/${measurementId}/media/`;
    const task = assetsManager.addMeshTask(`meshtask${measurementId}`, '', path, modelName);
    task.onSuccess = (result) => {
      const mesh = cleanUpGLTFAssets(result.loadedMeshes as [AbstractMesh, AbstractMesh]);
      resolve(mesh);
    };
    assetsManager.loadAsync();
  });
}

export interface Authentication {
  authClient: AuthClient;
  accessTokenExpiryThresholdMs: number;
}

export function enhanceRequestsWithAuthHeader(authenticationToken: BearerAccessToken) {
  // eslint-disable-next-line dot-notation
  WebRequest.CustomRequestHeaders['Authorization'] = authenticationToken;
}

/**
 * Load mesh into babylon scene
 *
 * For local files use URL.createObjectURL([loaded file])
 * TODO run experiments with local files and provide more info in the description
 *
 * @param scene babylon scene
 * @param measurementId
 * @param modelName name of the model
 * @param enable  enable or disable mesh in the Babylon scene
 * @param onCompleted function that is called if provided when mesh loading is completed
 */
export async function loadMesh(
  scene: Scene,
  assetsUrl: string,
  asset3dModelFilename: string,
  uniqueMeshName: string,
  loadingStrategy: 'glb' | 'gltf' = 'glb'
): Promise<{ mesh: Mesh; isNew: boolean; hasTexture: boolean }> {
  const mesh = scene.getMeshByName(uniqueMeshName);
  if (mesh) {
    const predefinedTexture = scene.getTextureByName(uniqueMeshName);
    return {
      mesh: mesh as Mesh,
      isNew: false,
      hasTexture: isDefined(predefinedTexture),
    };
  } else {
    const assets = await SceneLoader.LoadAssetContainerAsync(assetsUrl, asset3dModelFilename, scene, undefined, `.${loadingStrategy}`);

    const mesh = cleanUpGLTFAssets(assets.meshes as [AbstractMesh, AbstractMesh]);

    const material = mesh.material as Nullable<PBRMaterial>;
    const albedoTexture = material?.albedoTexture;
    if (isDefined(albedoTexture)) {
      albedoTexture.name = uniqueMeshName;
      scene.addTexture(albedoTexture);
    }

    return {
      mesh: mesh,
      isNew: true,
      hasTexture: isDefined(albedoTexture),
    };
  }
}
