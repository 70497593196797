import { Scene } from '@babylonjs/core';
import { CustomMaterial } from '@babylonjs/materials';

const UNIFORM_NAME_HEIGHT = 'height';

export class PointCloudHeightRegistrationMaterial extends CustomMaterial {
  public constructor(name: string, scene: Scene, private selectingUpperPart = true) {
    super(name, scene);
    this.setupMaterial();
    this.backFaceCulling = false;
  }

  public setHeight(heightInMeters: number) {
    this.onBindObservable.addOnce(() => {
      this.getEffect().setFloat(UNIFORM_NAME_HEIGHT, heightInMeters);
    });
  }

  private setupMaterial() {
    this.Fragment_Definitions(`
        const vec4 HIGHLIGHT_COLOR = vec4(1.0, 0.0, 0.0, 1.0);
        const float HIGHLIGHT_COLOR_TRANSPARENCY = 0.25;
    `);

    const highlightColorLevel = this.selectingUpperPart ? `step(${UNIFORM_NAME_HEIGHT}, vPositionW.z)` : `1. - step(${UNIFORM_NAME_HEIGHT}, vPositionW.z)`;

    this.Fragment_Custom_Diffuse(`
        float highlightColorLevel = ${highlightColorLevel};
        baseColor = mix(baseColor, HIGHLIGHT_COLOR, highlightColorLevel * HIGHLIGHT_COLOR_TRANSPARENCY);
    `);

    this.AddUniform(UNIFORM_NAME_HEIGHT, 'float', 0);
  }
}
