import { settings } from '@rhim/design';
import { modalCancelButton, modalPrimaryButton } from '@rhim/test-ids';
import styled from 'styled-components';

import { Button } from '../Button/Button';

export const DEFAULT_MODAL_WIDTH_PX = 520;

export const Headline = styled.span`
  display: block;
  font-size: ${settings.typography.FontSize.X_Large};
  font-family: ${settings.typography.FontFamily.Bold};
  color: ${settings.colors.Primary.Grey_8};
  margin-bottom: ${settings.Spacing.Spacing_500};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;

  button:not(:first-of-type) {
    margin-top: ${settings.Spacing.Spacing_300};
  }
`;

export const ButtonPrimary: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Button>>> = (props) => {
  return <Button dataTestId={modalPrimaryButton} size="medium-48" background="primary-9-brand" {...props} />;
};

export const ButtonCancel: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Button>>> = (props) => {
  return <Button dataTestId={modalCancelButton} size="x-small-32" mode="ghost" {...props} />;
};
