/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AbstractMesh,
  ArcRotateCamera,
  BoundingBox,
  Camera,
  Color3,
  Color4,
  DynamicTexture,
  Engine,
  HemisphericLight,
  Matrix,
  Mesh,
  MeshBuilder,
  Nullable,
  Observable,
  Observer,
  PointerDragBehavior,
  PointerEventTypes,
  PointerInfo,
  Scene,
  StandardMaterial,
  Tools,
  TransformNode,
  Vector3,
  Viewport,
} from '@babylonjs/core';
import { settings } from '@rhim/design';
import { RHIMAPOWearManagementApiClientOrientationLabelDto } from '@rhim/rest/measurementService';
import { assert, ensure, hasElements, isDefined } from '@rhim/utils';

import { getCenter, getMeshWorldSize } from '../meshUtils';
import { getDefaultCameraRadius, initializeCameraView } from '../sceneUtils';
import {
  HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO,
  HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO,
  HeightRegistrationMouseWheelInput,
  setOrthographicCoordinates,
  ViewBounds,
} from './HeightRegistrationMouseWheelInput';
import { PointCloudHeightRegistrationMaterial } from './PointCloudHeightRegistrationMaterial';
import { createArrow, shuffleNValuesOfArray } from './utils';

export const VOXEL_SIZE = 0.02;

export const DEFAULT_CAMERA_VIEW = {
  ALPHA: 0,
  BETA: Math.PI / 3,
};

const betaLowerLimit = 0.001;
const betaUpperLimit = Math.PI - 0.001;
const compassNodeName = 'compassNode';

export interface PointCloudOptions {
  points: number[];
  /**
   * Transformation is used to align point cloud with axes and zero degree
   * This is a transformation which should be applied before registration
   * process
   */
  permanentTransformation: Matrix;
  colors?: number[];
  uniformColor?: Color3;
  /**
   * Transformation user to align the point cloud with target
   * Use it as a temporary transformation
   */
  transformation?: Matrix;
  name?: string;
}

export interface SelectedPointMarker {
  offset: [number, number];
  isVisible: boolean;
}

export interface OrthographicViewsUpAxisAlignmentHandler {
  hide: () => void;
}

export interface OrthographicViewsHeightRegistrationHandler {
  setHeightRegistration: (height: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  resetView: () => void;
  hide: () => void;
}

export interface PCDScene3dAPIFacade {
  onPointPickedObservable: Observable<PickedPoint>;
  showOrthographicViewsUpAxisAlignment: () => OrthographicViewsUpAxisAlignmentHandler;
  showOrthographicViewsHeightRegistration: (
    sourceMesh: AbstractMesh,
    onViewBoundsChanged: (bounds: ViewBounds) => void,
    isTopPositionSelection?: boolean
  ) => OrthographicViewsHeightRegistrationHandler;
  setClearColor: (color: Color4) => void;
  resize: () => void;
  getEngine: () => Engine;
  getScene: () => Scene;
  getMainCamera: () => ArcRotateCamera;
  getPCD: (id: string, refreshBoundingInfo?: boolean) => Nullable<AbstractMesh>;
  addPCD: (options: PointCloudOptions) => Nullable<string>;
  rotateAlongAxis: (meshName: string | AbstractMesh, angle: number, axis: 'x' | 'y' | 'z', useGlobalOrigin?: boolean) => Matrix;
  movePCDToOrigin: (meshName: string | Mesh) => void;
  dragPCDAlongPlane: (meshName: string | Mesh, planeNormal: Vector3) => Nullable<PointerDragBehavior>;
  detachDragBehavior(mesh: string | Mesh): void;
  hardSceneCleanUp: () => void;
  transformModel: (id: string, transformation: Matrix) => void;
  showZAxis: () => void;
  showCompass: (meshId: string, displayMarkers: RHIMAPOWearManagementApiClientOrientationLabelDto[], showCrosshair?: boolean) => void;
  removeCompass: () => void;
  hideZAxis: () => void;
  dispose: () => void;
  setCamera: (options: { alpha?: number; beta?: number; lock?: boolean; target?: Vector3 }) => void;
  updateSelectedPointsMarkers: (markedPoints: Vector3[]) => SelectedPointMarker[];
  initLoadingPreview: () => LoadingPreview;
}

export interface PickedPoint {
  pickedPoint: Vector3;
  meshId: string;
}

interface LoadingPreview {
  addPoints: (newPositions: [number, number, number][], newColors: [number, number, number][] | undefined) => void;
  getAccumulatedPoints: () => { accumulatedPositions: [number, number, number][]; accumulatedColors: [number, number, number][] };
  remove: () => void;
}

// use negative cutting level to let navigating closer in Orthographic view
const CAMERA_CUTTING_LEVEL = -5;

export class PCDScene3dApi implements PCDScene3dAPIFacade {
  private readonly engine: Engine;
  private readonly scene: Scene;
  private readonly camera: ArcRotateCamera;
  private zAxis: Nullable<Mesh> = null;
  private currentOrigin = Vector3.Zero();
  private boundingBox: Nullable<BoundingBox> = null;

  public onPointPickedObservable: Observable<PickedPoint>;
  private onViewMatrixChangedObservable: Observer<Camera>;
  onResizeObservable: Observer<Engine>;

  private constructor(engine: Engine) {
    this.engine = engine;
    this.scene = new Scene(engine);
    this.scene.useRightHandedSystem = true;

    this.scene.clearColor = new Color4(1, 1, 1, 1);
    this.camera = new ArcRotateCamera('camera', DEFAULT_CAMERA_VIEW.ALPHA, DEFAULT_CAMERA_VIEW.BETA, 10, Vector3.Zero(), this.scene);
    this.camera.wheelPrecision = 80;
    /**
     * Vessels point clouds are pointing Z axis, therefore it is convenient
     * to rotate camera around Z, so touching feels more natural
     */
    this.camera.upVector = new Vector3(0, 0, 1);
    this.camera.attachControl(true, true);
    this.camera.lowerRadiusLimit = 0;
    this.camera.lowerAlphaLimit = null;
    this.camera.upperAlphaLimit = null;
    const light = new HemisphericLight('followLight', Vector3.Down(), this.scene);
    light.groundColor = new Color3(1, 1, 1);
    light.specular = Color3.Black();
    light.direction = this.camera.position;
    this.onPointPickedObservable = new Observable();
    this.registerPickingEvent();
    this.camera.mode = Camera.ORTHOGRAPHIC_CAMERA;
    this.camera.minZ = CAMERA_CUTTING_LEVEL;

    const rescaleCamera = (camera: ArcRotateCamera) => {
      const halfHeight = camera.radius / 2;
      // take into account the primary perspective camera's viewport which may have an arbitrary width/height ratio (other than 1:1)
      const { width: viewportWidth, height: viewportHeight } = camera.viewport;
      const { width, height } = this.engine.getRenderingCanvasClientRect() as DOMRect;
      const ratio = (width * viewportWidth) / (height * viewportHeight);
      const halfWidth = (ratio * camera.radius) / 2;
      camera.orthoBottom = -halfHeight;
      camera.orthoTop = halfHeight;
      camera.orthoLeft = -halfWidth;
      camera.orthoRight = halfWidth;
      camera.minZ = CAMERA_CUTTING_LEVEL;
    };

    this.scene.onReadyObservable.addOnce(() => {
      rescaleCamera(this.camera);
    });
    this.onViewMatrixChangedObservable = this.camera.onViewMatrixChangedObservable.add(() => {
      rescaleCamera(this.camera);
    });
    this.onResizeObservable = this.engine.onResizeObservable.add(() => {
      rescaleCamera(this.camera);
    });

    this.scene.onReadyObservable.addOnce(() => {
      this.resize();
    });

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  }

  public setClearColor(color: Color4) {
    this.scene.clearColor = color;
  }

  public showOrthographicViewsUpAxisAlignment() {
    const cameraTop = new ArcRotateCamera('top', 0, 0, this.camera.radius, this.camera.target, this.scene);
    cameraTop.upVector = this.camera.upVector;
    cameraTop.mode = Camera.ORTHOGRAPHIC_CAMERA;
    const cameraLeft = new ArcRotateCamera('left', -Math.PI / 2, Math.PI / 2, this.camera.radius, this.camera.target, this.scene);
    cameraLeft.upVector = this.camera.upVector;
    cameraLeft.mode = Camera.ORTHOGRAPHIC_CAMERA;
    const cameraFront = new ArcRotateCamera('front', 0, Math.PI / 2, this.camera.radius, this.camera.target, this.scene);
    cameraFront.upVector = this.camera.upVector;
    cameraFront.mode = Camera.ORTHOGRAPHIC_CAMERA;

    this.camera.viewport = new Viewport(0.5, 0.5, 0.5, 0.5);
    cameraTop.viewport = new Viewport(0, 0.5, 0.5, 0.5);
    cameraLeft.viewport = new Viewport(0.5, 0, 0.5, 0.5);
    cameraFront.viewport = new Viewport(0, 0, 0.5, 0.5);
    this.scene.activeCameras = [this.camera, cameraTop, cameraLeft, cameraFront];

    const rescaleOtherCameras = () => {
      this.scene.activeCameras?.forEach((subCamera) => {
        if (subCamera !== this.camera) {
          subCamera.orthoBottom = this.camera.orthoBottom;
          subCamera.orthoTop = this.camera.orthoTop;
          subCamera.orthoLeft = this.camera.orthoLeft;
          subCamera.orthoRight = this.camera.orthoRight;
          subCamera.minZ = CAMERA_CUTTING_LEVEL;
        }
      });
    };

    const onReadyObserver = this.scene.onReadyObservable.addOnce(() => {
      rescaleOtherCameras();
    });
    const onViewMatrixChangedObserver = this.camera.onViewMatrixChangedObservable.add(() => {
      rescaleOtherCameras();
    });
    const onResizeObserver = this.engine.onResizeObservable.add(() => {
      rescaleOtherCameras();
    });

    return {
      hide: () => {
        this.resetViewports();
        onReadyObserver.remove();
        onViewMatrixChangedObserver.remove();
        onResizeObserver.remove();
      },
    };
  }

  resetViewports() {
    this.scene.activeCameras = [this.camera];
    this.camera.viewport.x = 0;
    this.camera.viewport.y = 0;
    this.camera.viewport.width = 1;
    this.camera.viewport.height = 1;
  }

  public showOrthographicViewsHeightRegistration(sourceMesh: AbstractMesh, onViewBoundsChanged: (bounds: ViewBounds) => void, isTopPositionSelection = true) {
    const originalMeshMaterial = sourceMesh.material;
    sourceMesh.material = new PointCloudHeightRegistrationMaterial('pointCloudHeightRegistrationMaterial', this.getScene(), isTopPositionSelection);

    const meshSize = getMeshWorldSize(sourceMesh);

    const { width, height } = this.engine.getRenderingCanvasClientRect() as DOMRect;

    const meshCenter = getCenter(sourceMesh);

    // CAMERA FRONT (shown at left main panel)
    const cameraFront = new ArcRotateCamera('front', 0, Math.PI / 2, this.camera.radius, meshCenter, this.scene);
    cameraFront.upVector = this.camera.upVector;
    cameraFront.mode = Camera.ORTHOGRAPHIC_CAMERA;
    // Remove default mouse buttons handling (e.g remove left-mouse drag which we do not want for this view )
    cameraFront.inputs.remove(cameraFront.inputs.attached['pointers']!);
    // Remove default ArcRotateCamera mouse-wheel handling...
    cameraFront.inputs.remove(cameraFront.inputs.attached['mousewheel']!);
    // ... and instead attach our own custom mouse-wheel handling
    const customMouseWheelInput = new HeightRegistrationMouseWheelInput(meshSize, onViewBoundsChanged);
    cameraFront.inputs.add(customMouseWheelInput);
    customMouseWheelInput.resetView();

    // CAMERA TOP (shown at top right sub panel)
    const cameraTop = new ArcRotateCamera('top', 0, 0, this.camera.radius, meshCenter, this.scene);
    cameraTop.upVector = this.camera.upVector;
    cameraTop.mode = Camera.ORTHOGRAPHIC_CAMERA;

    const updateCameraTop = () => {
      const { width, height } = this.engine.getRenderingCanvasClientRect() as DOMRect;
      setOrthographicCoordinates(
        cameraTop,
        meshSize.y,
        meshSize.x,
        0.9,
        width * (1 - HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO),
        height * HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO
      );
    };
    updateCameraTop();

    // CAMERA PERSPECTIVE (shown at bottom right sub panel)
    setOrthographicCoordinates(
      this.camera,
      meshSize.y,
      meshSize.x,
      0.9,
      width * (1 - HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO),
      height * HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO
    );
    initializeCameraView(this.scene, this.camera, {
      cameraRotation: { alpha: DEFAULT_CAMERA_VIEW.ALPHA, beta: DEFAULT_CAMERA_VIEW.BETA },
    });

    // **** SETUP CAMERAS VIEWPORTS ****
    // FRONT VIEWPORT (shown at the main left side panel)
    cameraFront.viewport = new Viewport(0, 0, HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO, 1);
    // TOP VIEWPORT (shown at top right subpanel)
    cameraTop.viewport = new Viewport(
      HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO,
      HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO,
      1 - HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO,
      HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO
    );
    // PERSPECTIVE VIEWPORT (shown at bottom right subpanel)
    this.camera.viewport = new Viewport(
      HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO,
      0,
      1 - HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO,
      HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO
    );
    this.scene.activeCameras = [this.camera, cameraFront, cameraTop];

    enum ViewportId {
      frontView,
      topView,
      perspectiveView,
    }

    // As the user moves the mouse around, work out which viewport he is currently hovering.
    // Attach input controls to the camera assosiated with the currently hovered viewport and
    // detach input controls from the other cameras. This will, for example, make a mouse wheel event only
    // affect the currently hovered viewport.
    let currentViewportId: ViewportId | undefined;
    const onPointerObserver = this.scene.onPointerObservable.add((pointerInfo: PointerInfo) => {
      if (pointerInfo.type !== PointerEventTypes.POINTERMOVE) {
        return;
      }

      const { width, height } = this.engine.getRenderingCanvasClientRect() as DOMRect;
      const frontViewScreenWidth = width * HEIGHT_REGISTRATION_FRONT_VIEW_WIDTH_RATIO;
      const sideViewsScreenHeight = height * HEIGHT_REGISTRATION_SIDE_VIEWS_VERTICAL_HEIGHT_RATIO;
      if (pointerInfo.event.offsetX < frontViewScreenWidth) {
        if (currentViewportId !== ViewportId.frontView) {
          currentViewportId = ViewportId.frontView;
          this.camera.detachControl();
          cameraFront.attachControl();
        }
      } else if (pointerInfo.event.offsetX > frontViewScreenWidth && pointerInfo.event.offsetY > sideViewsScreenHeight) {
        if (currentViewportId !== ViewportId.perspectiveView) {
          currentViewportId = ViewportId.perspectiveView;
          this.camera.attachControl();
          cameraFront.detachControl();
        }
      } else {
        currentViewportId = undefined;
        this.camera.detachControl();
        cameraFront.detachControl();
      }
    });

    const onResizeObserver = this.engine.onResizeObservable.add(() => {
      customMouseWheelInput.updateView(true);
      updateCameraTop();
    });

    return {
      setHeightRegistration: (height: number) => {
        customMouseWheelInput.setHeight(height);
      },
      zoomIn: () => {
        customMouseWheelInput.zoomView(true, true);
      },
      zoomOut: () => {
        customMouseWheelInput.zoomView(false, true);
      },
      resetView: () => {
        customMouseWheelInput.resetView();
      },
      hide: () => {
        this.camera.attachControl();
        this.resetViewports();
        onPointerObserver.remove();
        onResizeObserver.remove();
        sourceMesh.material = originalMeshMaterial;
      },
    };
  }

  public dispose() {
    this.onViewMatrixChangedObservable.remove();
    this.scene.onPointerObservable.clear();
    this.onResizeObservable.remove();
    this.onPointPickedObservable.clear();
    this.scene.getEngine().dispose();
  }

  public setCamera(options: { alpha?: number; beta?: number; lock?: boolean; target?: Vector3 }): void {
    const { alpha, beta, lock, target } = options;
    if (isDefined(target)) {
      this.camera.target = target;
    }

    if (isDefined(alpha)) {
      this.camera.alpha = alpha;
    }

    if (isDefined(beta)) {
      this.camera.beta = beta;
    }

    if (lock === true) {
      this.camera.lowerAlphaLimit = this.camera.alpha;
      this.camera.upperAlphaLimit = this.camera.alpha;
      this.camera.lowerBetaLimit = this.camera.beta;
      this.camera.upperBetaLimit = this.camera.beta;
    } else {
      this.camera.lowerAlphaLimit = null;
      this.camera.upperAlphaLimit = null;
      this.camera.lowerBetaLimit = betaLowerLimit;
      this.camera.upperBetaLimit = betaUpperLimit;
    }
  }

  private registerPickingEvent() {
    let selectedPointId = -1;

    this.scene.onPointerObservable.add((pointerInfo: PointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
        case PointerEventTypes.POINTERTAP:
          selectedPointId = isDefined(pointerInfo.pickInfo) ? pointerInfo.pickInfo.thinInstanceIndex : -1;
          break;
        case PointerEventTypes.POINTERMOVE:
          selectedPointId = -1;
          break;
        case PointerEventTypes.POINTERUP:
          if (isDefined(pointerInfo.pickInfo) && selectedPointId > -1) {
            const mesh = ensure(pointerInfo.pickInfo).pickedMesh as Mesh;
            const mat = mesh.thinInstanceGetWorldMatrices()[selectedPointId]!;
            const position = Vector3.TransformCoordinates(mat.getTranslation(), mesh.getWorldMatrix());
            mesh.getWorldMatrix();
            this.onPointPickedObservable.notifyObservers({
              meshId: mesh.id,
              pickedPoint: position,
            });
          }
          selectedPointId = -1;
          break;
      }
    });
  }

  public showZAxis() {
    if (this.zAxis === null || this.zAxis.isDisposed()) {
      this.zAxis = null;
      this.zAxis = createArrow(
        'z_axis',
        {
          length: 2,
          origin: this.currentOrigin,
          direction: new Vector3(0, 0, 1),
        },
        this.scene
      );
    } else {
      this.zAxis.isVisible = true;
    }
    this.zAxis.renderingGroupId = 1;
  }

  public showCompass(meshId: string, displayMarkers: RHIMAPOWearManagementApiClientOrientationLabelDto[], showCrosshair = true) {
    const DYNAMIC_TEXTURE_SIZE = 2048;
    const DYNAMIC_TEXTURE_SIZE_HALF = DYNAMIC_TEXTURE_SIZE / 2;
    const LINE_WIDTH_PX = 15;

    function createBottomCompassTexture(scene: Scene) {
      const dynamicTexture = new DynamicTexture('orientationCompassDynamicTexture', DYNAMIC_TEXTURE_SIZE, scene);
      dynamicTexture.hasAlpha = true;
      const ctx = dynamicTexture.getContext() as CanvasRenderingContext2D;
      ctx.clearRect(0, 0, DYNAMIC_TEXTURE_SIZE, DYNAMIC_TEXTURE_SIZE);

      ctx.lineWidth = LINE_WIDTH_PX;
      const fontSizeZeroDegrees = '90px';
      const fontSizeDefault = '72px';
      ctx.font = `${fontSizeDefault} nortw05-bold`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      const metrics = ctx.measureText('0');
      const maxLabelHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
      const labelMarginDefaultPx = maxLabelHeight;
      const compassLineLength = DYNAMIC_TEXTURE_SIZE_HALF - 2 * (maxLabelHeight + labelMarginDefaultPx);
      const compassCircleRadiusPx = compassLineLength - labelMarginDefaultPx;

      // PAINT COMPASS CIRCLE
      ctx.beginPath();
      ctx.arc(DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF, compassCircleRadiusPx, 0, 2 * Math.PI);
      ctx.strokeStyle = settings.colors.Primary.Grey_4;
      ctx.stroke();

      // If there are no displayMarkers defined in the backend, setup 45 degree step angles by default
      if (!hasElements(displayMarkers)) {
        const defaultAngles = [0, 45, 90, 135, 180, 225, 270, 315];
        for (const defaultAngle of defaultAngles) {
          displayMarkers.push({ angle: defaultAngle });
        }
      } else {
        // Always add a line at 0 degrees , even if none is defined in the displayMarkers
        const hasZeroDegreeMarker = displayMarkers.find((displayMarker) => displayMarker.angle === 0);
        if (!hasZeroDegreeMarker) {
          const zeroDegreeMarker: RHIMAPOWearManagementApiClientOrientationLabelDto = { angle: 0 };
          displayMarkers.push(zeroDegreeMarker);
        }
      }

      for (const marker of displayMarkers) {
        ctx.save();

        ctx.translate(DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF);
        ctx.rotate(Tools.ToRadians(marker.angle));
        // PAINT COMPASS LINE
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(compassLineLength, 0);
        ctx.strokeStyle = settings.colors.Primary.Grey_4;
        ctx.stroke();
        // PAINT MARKER DEGREES
        ctx.fillStyle = marker.angle === 0 ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_6;
        ctx.font = `${marker.angle === 0 ? fontSizeZeroDegrees : fontSizeDefault} nortw05-bold`;
        ctx.translate(compassLineLength + labelMarginDefaultPx, 0);
        const angleToRotateRadians = marker.angle >= 90 && marker.angle <= 270 ? Math.PI / 2 : -Math.PI / 2;
        ctx.rotate(angleToRotateRadians);
        ctx.fillText(marker.angle.toString(), 0, 0);
        ctx.rotate(-angleToRotateRadians);
        // PAINT MARKER TEXT
        if (isDefined(marker.displayText)) {
          ctx.translate(maxLabelHeight + labelMarginDefaultPx, 0);
          ctx.rotate(angleToRotateRadians);
          ctx.fillText(marker.displayText, 0, 0);
          ctx.rotate(-angleToRotateRadians);
        }
        ctx.restore();
      }
      dynamicTexture.update();

      return { dynamicTexture, compassCircleRadiusPx };
    }

    function createTopCompassTexture(scene: Scene, compassCircleRadiusPx: number) {
      const dynamicTexture = new DynamicTexture('topCompassTexture', DYNAMIC_TEXTURE_SIZE, scene);
      dynamicTexture.hasAlpha = true;
      const ctx = dynamicTexture.getContext() as CanvasRenderingContext2D;
      ctx.clearRect(0, 0, DYNAMIC_TEXTURE_SIZE, DYNAMIC_TEXTURE_SIZE);

      const WHITE_OUTLINE_WIDTH_PX = LINE_WIDTH_PX / 2;

      function paintDoubleLine(start: [number, number], end: [number, number], fill: settings.colors.Any) {
        // Paint thick white line
        ctx.beginPath();
        ctx.moveTo(start[0], start[1]);
        ctx.lineTo(end[0], end[1]);
        ctx.lineWidth = LINE_WIDTH_PX + 2 * WHITE_OUTLINE_WIDTH_PX;
        ctx.strokeStyle = settings.colors.Monochromatic.White;
        ctx.stroke();
        // Paint "main" blue line
        ctx.beginPath();
        ctx.moveTo(start[0], start[1]);
        ctx.lineTo(end[0], end[1]);
        ctx.lineWidth = LINE_WIDTH_PX;
        ctx.strokeStyle = fill;
        ctx.stroke();
      }

      // PAINT PRIMARY VERTICAL LINE
      const primaryLinesFill = settings.colors.Primary.Blue_9;
      paintDoubleLine(
        [DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF - compassCircleRadiusPx],
        [DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF + compassCircleRadiusPx],
        primaryLinesFill
      );
      // PAINT PRIMARY HORIZONTAL LINE
      paintDoubleLine(
        [DYNAMIC_TEXTURE_SIZE_HALF - compassCircleRadiusPx, DYNAMIC_TEXTURE_SIZE_HALF],
        [DYNAMIC_TEXTURE_SIZE_HALF + compassCircleRadiusPx, DYNAMIC_TEXTURE_SIZE_HALF],
        primaryLinesFill
      );
      // CLEAR UP SECTION WHERE THE CROSSHAIR WILL GO
      const CROSSHAIR_MARGIN_PX = 10;
      const crosshairSize = compassCircleRadiusPx / 10;
      const crosshairSizeWithMargin = crosshairSize + CROSSHAIR_MARGIN_PX;
      ctx.clearRect(
        DYNAMIC_TEXTURE_SIZE_HALF - crosshairSizeWithMargin,
        DYNAMIC_TEXTURE_SIZE_HALF - crosshairSizeWithMargin,
        2 * crosshairSizeWithMargin,
        2 * crosshairSizeWithMargin
      );
      // PAINT CROSSHAIR VERTICAL LINE
      const crosshairFill = settings.colors.Operational.State_Alert_Red_2;
      paintDoubleLine(
        [DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF - crosshairSize],
        [DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF + crosshairSize],
        crosshairFill
      );
      // PAINT CROSSHAIR HORIZONTAL LINE
      paintDoubleLine(
        [DYNAMIC_TEXTURE_SIZE_HALF - crosshairSize, DYNAMIC_TEXTURE_SIZE_HALF],
        [DYNAMIC_TEXTURE_SIZE_HALF + crosshairSize, DYNAMIC_TEXTURE_SIZE_HALF],
        crosshairFill
      );
      // PAINT CROSSHAIR BOX AROUND CENTER
      ctx.beginPath();
      ctx.lineWidth = WHITE_OUTLINE_WIDTH_PX;
      ctx.strokeStyle = settings.colors.Monochromatic.White;
      ctx.rect(
        DYNAMIC_TEXTURE_SIZE_HALF - (LINE_WIDTH_PX + WHITE_OUTLINE_WIDTH_PX) / 2,
        DYNAMIC_TEXTURE_SIZE_HALF - (LINE_WIDTH_PX + WHITE_OUTLINE_WIDTH_PX) / 2,
        LINE_WIDTH_PX + WHITE_OUTLINE_WIDTH_PX,
        LINE_WIDTH_PX + WHITE_OUTLINE_WIDTH_PX
      );
      ctx.stroke();
      // PAINT CROSSHAIR CENTER
      ctx.beginPath();
      ctx.fillStyle = settings.colors.Monochromatic.Black;
      ctx.fillRect(DYNAMIC_TEXTURE_SIZE_HALF - LINE_WIDTH_PX / 2, DYNAMIC_TEXTURE_SIZE_HALF - LINE_WIDTH_PX / 2, LINE_WIDTH_PX, LINE_WIDTH_PX);

      // PAINT OUTER CIRCLE
      ctx.beginPath();
      ctx.lineWidth = LINE_WIDTH_PX / 2;
      ctx.arc(DYNAMIC_TEXTURE_SIZE_HALF, DYNAMIC_TEXTURE_SIZE_HALF, compassCircleRadiusPx, 0, 2 * Math.PI);
      ctx.strokeStyle = settings.colors.Primary.Blue_9;
      ctx.stroke();

      dynamicTexture.update();

      return dynamicTexture;
    }

    const { dynamicTexture: bottomCompassTexture, compassCircleRadiusPx } = createBottomCompassTexture(this.scene);

    const mesh = this.getPCD(meshId);
    assert(isDefined(mesh), `showCompass, mesh with id : ${meshId} not found`);
    const { min, max } = mesh.getHierarchyBoundingVectors();
    const { x: meshWidth, y: meshLength } = max.subtract(min);
    const maximumDimensionMeters = Math.max(meshWidth, meshLength);

    function pixelsToWorld(valuePx: number) {
      return (maximumDimensionMeters * valuePx) / (2 * compassCircleRadiusPx);
    }

    const planeSize = pixelsToWorld(DYNAMIC_TEXTURE_SIZE);

    const compassNode = new TransformNode(compassNodeName, this.scene);

    // BOTTOM COMPASS MATERIAL
    const bottomCompassPlaneMaterial = new StandardMaterial('bottomCompassPlaneMaterial', this.scene);
    bottomCompassPlaneMaterial.disableLighting = true;
    bottomCompassPlaneMaterial.diffuseTexture = bottomCompassTexture;
    bottomCompassPlaneMaterial.emissiveColor = Color3.White();
    bottomCompassPlaneMaterial.diffuseColor = Color3.Red();
    bottomCompassPlaneMaterial.backFaceCulling = false;
    // BOTTOM COMPASS PLANE
    const bottomCompassPlane = MeshBuilder.CreatePlane('bottomCompassPlane', { width: planeSize, height: planeSize }, this.scene);
    bottomCompassPlane.isPickable = false;
    bottomCompassPlane.material = bottomCompassPlaneMaterial;
    bottomCompassPlane.scaling.z = -1;
    // Position compass plane at the bottom of the mesh
    bottomCompassPlane.position.z = min.z;
    bottomCompassPlane.parent = compassNode;

    if (showCrosshair) {
      const topCompassTexture = createTopCompassTexture(this.scene, compassCircleRadiusPx);
      // TOP COMPASS MATERIAL
      const topCompassPlaneMaterial = new StandardMaterial('topCompassPlaneMaterial', this.scene);
      topCompassPlaneMaterial.disableLighting = true;
      topCompassPlaneMaterial.diffuseTexture = topCompassTexture;
      topCompassPlaneMaterial.emissiveColor = Color3.White();
      topCompassPlaneMaterial.diffuseColor = Color3.Red();
      topCompassPlaneMaterial.backFaceCulling = false;
      // TOP COMPASS PLANE
      const topCompassPlane = MeshBuilder.CreatePlane('topCompassPlane', { width: planeSize, height: planeSize }, this.scene);
      topCompassPlane.isPickable = false;
      topCompassPlane.material = topCompassPlaneMaterial;
      // Position top compass plane at the top of the mesh
      topCompassPlane.position.z = max.z;
      topCompassPlane.parent = compassNode;
    }
  }

  public removeCompass() {
    this.scene.getNodeByName(compassNodeName)?.dispose();
  }

  public hideZAxis() {
    if (this.zAxis !== null) {
      this.zAxis.isVisible = false;
    }
  }

  public resize() {
    this.engine.resize();
  }

  public getEngine() {
    return this.engine;
  }

  /**
   * Method cleans the scene from its geometries. All meshes will be disposed along with its
   * attached materials and textures.
   * After disposing, we still have disposed objects and it is possible that we are keeping the
   * references to the disposed instances. Therefore, NULL assignment should help GC to collect
   */
  public hardSceneCleanUp() {
    // remapping the meshes to the new array because this.scene.meshes is mutated on each mesh disposal
    [...this.scene.meshes].forEach((mesh) => {
      mesh.dispose(false, true);
      (mesh as Nullable<Mesh>) = null;
    });
    this.scene.meshes = [];
  }

  /**
   * Finds the mesh in the scene. When you are dealing with thin instances and planning to compute
   * its dimentions, set refreshBoundingInfo to true. Beware that it is a heavy calculation, as
   * matrix buffer should be synchronized with GPU.
   *
   * In case refreshBoundingInfo is set to true, but we do not find a geometry by the id or it is not
   * an instance of Mesh, calculations will be ignored
   *
   * @param id
   * @param refreshBoundingInfo
   */
  public getPCD(id: string, refreshBoundingInfo = false) {
    const mesh = this.scene.getMeshById(id);

    if (mesh instanceof Mesh && refreshBoundingInfo) {
      mesh.thinInstanceRefreshBoundingInfo();
      this.boundingBox = mesh.getBoundingInfo().boundingBox;
      // in case we decomposed the matrix into nodes Quaternion and Translation, world matrix should also be updated
      mesh.computeWorldMatrix();
    }

    return mesh;
  }

  public transformModel(id: string, transformation: Matrix) {
    const mesh = this.scene.getMeshById(id);

    if (isDefined(mesh)) {
      const wm = mesh.computeWorldMatrix(true);
      wm.copyFrom(wm.multiply(transformation));
    }
  }

  public initLoadingPreview(): LoadingPreview {
    let box = MeshBuilder.CreateBox('ptsThinInstancedBox', { size: VOXEL_SIZE }, this.scene);
    const accumulatedPositions: [number, number, number][] = [];
    const accumulatedColors: [number, number, number][] = [];

    function addInstances(positions: [number, number, number][]) {
      const matrices = positions.map((position) => {
        const [x, y, z] = position;
        return Matrix.Translation(x, y, z);
      });
      box.thinInstanceAdd(matrices);
    }

    function setColorsBuffer(colors: [number, number, number][]) {
      const colorBuffer = colors.flatMap((c) => [c[0] / 255, c[1] / 255, c[2] / 255, 1.0]);
      box.thinInstanceSetBuffer('color', new Float32Array(colorBuffer), 4);
    }

    box.isPickable = false;
    const mat = new StandardMaterial('plugMaterial', this.scene);
    mat.disableLighting = true;
    mat.emissiveColor = Color3.White();
    box.material = mat;

    const camera = this.getMainCamera();
    // Temporarily disable camera interactions whilst the preview is in progress
    camera.detachControl();

    return {
      addPoints: (newPositions: [number, number, number][], newColors: [number, number, number][] | undefined) => {
        // Accumulate new point's positions & colors
        accumulatedPositions.push(...newPositions);
        if (isDefined(newColors)) {
          accumulatedColors.push(...newColors);
        }
        addInstances(newPositions);
        setColorsBuffer(accumulatedColors);
        const { center, minimumWorld, maximumWorld } = box.getBoundingInfo().boundingBox;
        camera.setTarget(center);
        camera.alpha = DEFAULT_CAMERA_VIEW.ALPHA;
        camera.beta = DEFAULT_CAMERA_VIEW.BETA;
        camera.radius = getDefaultCameraRadius(minimumWorld, maximumWorld);
      },
      getAccumulatedPoints: () => ({ accumulatedPositions, accumulatedColors }),
      remove: () => {
        camera.attachControl();
        box.dispose(false, true);
        // kindly ask GC to remove this giant
        (box as Nullable<Mesh>) = null;
        accumulatedPositions.length = 0;
        accumulatedColors.length = 0;
      },
    };
  }

  public addPCD(options: PointCloudOptions) {
    const { points, permanentTransformation, colors, uniformColor, transformation, name } = options;
    const id = isDefined(name) ? name : String(this.scene.getUniqueId());
    const pointsNumber = points.length / 3;

    const particleMesh = MeshBuilder.CreateBox(id, { size: VOXEL_SIZE }, this.scene);
    const mat = new StandardMaterial(`${id}_material`, this.scene);
    mat.disableLighting = true;
    mat.emissiveColor = isDefined(uniformColor) ? uniformColor : Color3.White();
    particleMesh.material = mat;

    // limiting number of points do not kill the whole performance if the point cloud is too big
    const pointsLimit = 1e6;
    let indices = new Array(pointsNumber).fill(0).map((_, i) => i);

    if (pointsNumber > pointsLimit) {
      shuffleNValuesOfArray(indices, pointsLimit);
      indices = indices.slice(0, pointsLimit);
    }

    const renderingPointsNumber = indices.length;
    const pointsMatrices = new Float32Array(16 * renderingPointsNumber);
    const colorsBuffer = new Float32Array(4 * renderingPointsNumber);

    for (let i = 0; i < renderingPointsNumber; i += 1) {
      const idx = indices[i]!;
      // translating point to its position
      const translation = Matrix.Translation(points[3 * idx + 0]!, points[3 * idx + 1]!, points[3 * idx + 2]!);
      translation.copyToArray(pointsMatrices, 16 * idx);

      // setting colors per point
      if (isDefined(colors)) {
        const color = Color3.FromInts(colors[3 * idx + 0]!, colors[3 * idx + 1]!, colors[3 * idx + 2]!);
        colorsBuffer[idx * 4 + 0] = color.r;
        colorsBuffer[idx * 4 + 1] = color.g;
        colorsBuffer[idx * 4 + 2] = color.b;
        colorsBuffer[idx * 4 + 3] = 1;
      } else {
        colorsBuffer[idx * 4 + 0] = 1;
        colorsBuffer[idx * 4 + 1] = 1;
        colorsBuffer[idx * 4 + 2] = 1;
        colorsBuffer[idx * 4 + 3] = 1;
      }
    }

    particleMesh.thinInstanceSetBuffer('matrix', pointsMatrices, 16);
    particleMesh.thinInstanceSetBuffer('color', colorsBuffer, 4);

    particleMesh.thinInstanceEnablePicking = true;
    particleMesh.useOctreeForPicking = true;

    // Showing bounding box. For debugging only.
    // this.scene.getBoundingBoxRenderer().frontColor.set(1, 0, 0);
    // this.scene.getBoundingBoxRenderer().backColor.set(0, 1, 0);
    // particleMesh.showBoundingBox = true;

    /**
     * This how we are gaining A LOT OF PERFORMANCE byt keeping all computations on GPU and do not sync the
     * thin instance matrices which are on CPU.
     * !!BEWARE that the mesh will not update its bounding info, so `thinInstanceRefreshBoundingInfo` should be used.
     */
    particleMesh.doNotSyncBoundingInfo = true;

    const finalTransformation = permanentTransformation.clone();

    if (isDefined(transformation)) {
      finalTransformation.multiplyToRef(transformation, finalTransformation);
    }

    finalTransformation.decomposeToTransformNode(particleMesh);
    particleMesh.computeWorldMatrix(true);

    particleMesh.thinInstanceRefreshBoundingInfo();
    this.boundingBox = particleMesh.getBoundingInfo().boundingBox;

    this.camera.setTarget(Vector3.Zero());

    return id;
  }

  /**
   * Adds rotation along a given axis. Before rotation the model will be moved to origin
   * to keep rotation around center of the bounding box of the model.
   * Previous transformations are preserved, it means you need not setting angle but providing a
   * delta between the prev. state and the new one.
   *
   * @param mesh
   * @param angle
   * @param axis
   * @param useGlobalOrigin boolean flag to use global origin instead of the model's center
   */
  public rotateAlongAxis(mesh: string | AbstractMesh, angle: number, axis: 'x' | 'y' | 'z', useGlobalOrigin = false): Matrix {
    const model = String(mesh) === mesh ? this.scene.getMeshByName(mesh) : (mesh as AbstractMesh);
    if (!isDefined(model)) {
      return Matrix.Identity();
    }
    /**
     * Explicitly recompute mesh's world matrix in order to get an up-to-date matrix.
     * Do not just rely on model.getWorldMatrix() :
     * For this to be up-to-date babylonJS has to first complete the rendering of its frame.
     * However, this rotateAlongAxis function is called independently, whenever the UI's slider is dragged/changed and, depending on the timing,
     * model.getWorldMatrix() will intermittently yield outdated results.
     */
    const wm = model.computeWorldMatrix(true);
    const rotMat = axis === 'x' ? Matrix.RotationX(-angle) : axis === 'y' ? Matrix.RotationY(angle) : Matrix.RotationZ(angle);

    const resMat = Matrix.Identity();
    if (useGlobalOrigin) {
      resMat.multiplyToRef(wm.multiply(rotMat), resMat);
    } else {
      const { center } = model.getBoundingInfo().boundingBox;
      const { x, y, z } = Vector3.TransformCoordinates(center, model.getWorldMatrix());
      const moveToOriginMat = Matrix.Translation(-x, -y, -z);
      const moveBackMat = Matrix.Translation(x, y, z);

      resMat.multiplyToRef(wm.multiply(moveToOriginMat).multiply(rotMat).multiply(moveBackMat), resMat);
    }

    resMat.decomposeToTransformNode(model);

    return resMat;
  }

  public dragPCDAlongPlane(mesh: string | Mesh, planeNormal: Vector3): Nullable<PointerDragBehavior> {
    const model = String(mesh) === mesh ? (this.scene.getMeshByName(mesh) as Nullable<Mesh>) : (mesh as Mesh);

    if (!isDefined(model)) {
      return null;
    }

    const dragPlane = new PointerDragBehavior({ dragPlaneNormal: planeNormal });
    dragPlane.useObjectOrientationForDragging = false;
    dragPlane.attach(model);

    return dragPlane;
  }

  public movePCDToOrigin(mesh: string | Mesh): void {
    const model = String(mesh) === mesh ? (this.scene.getMeshByName(mesh) as Nullable<Mesh>) : (mesh as Mesh);

    if (!isDefined(model)) {
      return;
    }

    const { x, y, z } = model.getBoundingInfo().boundingBox.centerWorld;
    // including the movement to origin into the WM
    const moveToOriginMat = model.getWorldMatrix().multiply(Matrix.Translation(-x, -y, -z));
    // model.getWorldMatrix().copyFrom(moveToOriginMat);
    moveToOriginMat.decomposeToTransformNode(model);
    model.computeWorldMatrix(true);

    // updating bounding info before computing the offset vector
    model.thinInstanceRefreshBoundingInfo();
    this.boundingBox = model.getBoundingInfo().boundingBox;

    this.camera.setTarget(Vector3.Zero());
    this.camera.alpha = DEFAULT_CAMERA_VIEW.ALPHA;
    this.camera.beta = DEFAULT_CAMERA_VIEW.BETA;
    this.camera.radius = getDefaultCameraRadius(this.boundingBox.minimumWorld, this.boundingBox.maximumWorld);
  }

  public detachDragBehavior(mesh: string | Mesh): void {
    const model = String(mesh) === mesh ? this.scene.getMeshByName(mesh) : mesh;

    if (!isDefined(model)) {
      return;
    }

    (model as Mesh).getBehaviorByName(PointerDragBehavior.name)?.detach();
  }

  public static initAPI(canvasElement: HTMLCanvasElement) {
    const engine = new Engine(canvasElement, true, {
      preserveDrawingBuffer: true, // keep it in true for screenshots
      stencil: false,
    });
    engine.setHardwareScalingLevel(0.5);

    return new PCDScene3dApi(engine);
  }

  public getScene() {
    return this.scene;
  }

  public getMainCamera() {
    return this.camera;
  }

  public updateSelectedPointsMarkers(markedPoints: Vector3[]): SelectedPointMarker[] {
    if (!isDefined(this.boundingBox)) {
      return [];
    }
    const engineRenderSize: [number, number] = [this.engine.getRenderWidth(), this.engine.getRenderHeight()];
    const globalViewport = this.camera.viewport.toGlobal(engineRenderSize[0], engineRenderSize[1]);
    const sceneTransformMatrix = this.scene.getTransformMatrix();
    const engineHardwareScalingLevel = this.engine.getHardwareScalingLevel();
    // Use a reference point a point at the center of the mesh and at its vertical bottom
    const referencePoint = new Vector3(this.currentOrigin.x, this.currentOrigin.y, this.boundingBox.minimumWorld.z);
    const referencePointProjectedPosition = Vector3.Project(referencePoint, Matrix.Identity(), sceneTransformMatrix, globalViewport);

    const markers: SelectedPointMarker[] = [];
    for (const markedPoint of markedPoints) {
      const markedPointProjectedPosition = Vector3.Project(markedPoint, Matrix.Identity(), sceneTransformMatrix, globalViewport);
      // compare the 2d projections "z" values (the larger the z the more far away it is from our eyes).
      // if the marker's z is less than the reference point's z ( it is closer to us and "in front" of it ) we consider the marker visible
      const isMarkerVisible = markedPointProjectedPosition.z <= referencePointProjectedPosition.z;

      markers.push({
        offset: [markedPointProjectedPosition.x * engineHardwareScalingLevel, markedPointProjectedPosition.y * engineHardwareScalingLevel],
        isVisible: isMarkerVisible,
      });
    }
    return markers;
  }
}
