/* tslint:disable */
/* eslint-disable */
/**
 * CementService API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceDataPointCloudScansExtractionState = {
    InProgress: 'InProgress',
    Failed: 'Failed',
    Completed: 'Completed'
} as const;

export type RHIMCementServiceDataPointCloudScansExtractionState = typeof RHIMCementServiceDataPointCloudScansExtractionState[keyof typeof RHIMCementServiceDataPointCloudScansExtractionState];


/**
 * 
 * @export
 * @interface RHIMCementServiceDataPointCloudScansMatrix4x4
 */
export interface RHIMCementServiceDataPointCloudScansMatrix4x4 {
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm11': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm12': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm13': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm14': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm21': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm22': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm23': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm24': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm31': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm32': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm33': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm34': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm41': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm42': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm43': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansMatrix4x4
     */
    'm44': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceDataPointCloudScansPanoramaImagesState = {
    InProgress: 'InProgress',
    ProcessingFailed: 'ProcessingFailed',
    Completed: 'Completed'
} as const;

export type RHIMCementServiceDataPointCloudScansPanoramaImagesState = typeof RHIMCementServiceDataPointCloudScansPanoramaImagesState[keyof typeof RHIMCementServiceDataPointCloudScansPanoramaImagesState];


/**
 * 
 * @export
 * @interface RHIMCementServiceDataPointCloudScansPoint3D
 */
export interface RHIMCementServiceDataPointCloudScansPoint3D {
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansPoint3D
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansPoint3D
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceDataPointCloudScansPoint3D
     */
    'z': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceDataPointCloudScansRefractoryCondition = {
    NewLining: 'NewLining',
    PartiallyNewLining: 'PartiallyNewLining',
    WornRefractories: 'WornRefractories',
    CleanShell: 'CleanShell'
} as const;

export type RHIMCementServiceDataPointCloudScansRefractoryCondition = typeof RHIMCementServiceDataPointCloudScansRefractoryCondition[keyof typeof RHIMCementServiceDataPointCloudScansRefractoryCondition];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceDataPointCloudScansScanResolution = {
    Full: 'Full',
    Reduced: 'Reduced'
} as const;

export type RHIMCementServiceDataPointCloudScansScanResolution = typeof RHIMCementServiceDataPointCloudScansScanResolution[keyof typeof RHIMCementServiceDataPointCloudScansScanResolution];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceDataPointCloudScansScanState = {
    InProgress: 'InProgress',
    InitialProcessingFailed: 'InitialProcessingFailed',
    Completed: 'Completed'
} as const;

export type RHIMCementServiceDataPointCloudScansScanState = typeof RHIMCementServiceDataPointCloudScansScanState[keyof typeof RHIMCementServiceDataPointCloudScansScanState];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMCementServiceV1ControllersPanoramaImageQuality = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type RHIMCementServiceV1ControllersPanoramaImageQuality = typeof RHIMCementServiceV1ControllersPanoramaImageQuality[keyof typeof RHIMCementServiceV1ControllersPanoramaImageQuality];


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsCampaignDto
 */
export interface RHIMCementServiceV1ModelsCampaignDto {
    /**
     * A unique (per vessel) number that represents the campaign
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsCampaignDto
     */
    'id': number;
    /**
     * The text to be displayed for a campaign.
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsCampaignDto
     */
    'displayText': string;
    /**
     * This campaigns starts at this moment in time and ends when a new campaign starts.
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsCampaignDto
     */
    'startTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto
 */
export interface RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto {
    /**
     * A unique (per vessel) number that represents the campaign
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto
     */
    'id': number;
    /**
     * The text to be displayed for a campaign.
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto
     */
    'displayText': string;
    /**
     * This campaigns starts at this moment in time and ends when a new campaign starts.
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto
     */
    'startTimestamp': string;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsDrillingMeasurementDto
 */
export interface RHIMCementServiceV1ModelsDrillingMeasurementDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDrillingMeasurementDto
     */
    'rm': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDrillingMeasurementDto
     */
    'angle': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDrillingMeasurementDto
     */
    'remainingThickness': number;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsDustZoneDto
 */
export interface RHIMCementServiceV1ModelsDustZoneDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDustZoneDto
     */
    'rmFrom': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDustZoneDto
     */
    'rmTo': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDustZoneDto
     */
    'angleFrom': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsDustZoneDto
     */
    'angleTo': number;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsExtractionDto
 */
export interface RHIMCementServiceV1ModelsExtractionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'extractionDate': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansExtractionState}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'extractionState': RHIMCementServiceDataPointCloudScansExtractionState;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'logOutput'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'isPublished': boolean;
    /**
     * 
     * @type {Array<RHIMCementServiceV1ModelsDustZoneDto>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'dustZones': Array<RHIMCementServiceV1ModelsDustZoneDto>;
    /**
     * 
     * @type {RHIMCementServiceV1ModelsSliderRangeDto}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'remainingThicknessSliderRange'?: RHIMCementServiceV1ModelsSliderRangeDto | null;
    /**
     * 
     * @type {Array<RHIMCementServiceV1ModelsDrillingMeasurementDto>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'drillingMeasurements': Array<RHIMCementServiceV1ModelsDrillingMeasurementDto>;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansMatrix4x4}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'kilnAxisTransformationMatrix'?: RHIMCementServiceDataPointCloudScansMatrix4x4 | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'minRemainingThickness': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'medianRemainingThickness': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'maxRemainingThickness': Array<number>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RHIMCementServiceV1ModelsExtractionDto
     */
    'remainingThicknessTable': Array<Array<number>>;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsExtractionOverviewDto
 */
export interface RHIMCementServiceV1ModelsExtractionOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'extractionDate': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansExtractionState}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'extractionState': RHIMCementServiceDataPointCloudScansExtractionState;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'isPublished': boolean;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsExtractionOverviewDto
     */
    'dustZonesCount': number;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
 */
export interface RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto {
    /**
     * The length of the kiln in meters
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'kilnLength': number;
    /**
     * An array of minimum temperatures.  Each profile segment starts at RM = index * KilnLength / lengthOfArray  and ends at RM = (index + 1) * KilnLength / lengthOfArray  the center point is at RM = (index + 0.5) * KilnLength / lengthOfArray
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'minTemperatures': Array<number>;
    /**
     * An array of maximum temperatures.  Each profile segment starts at RM = index * KilnLength / lengthOfArray  and ends at RM = (index + 1) * KilnLength / lengthOfArray  the center point is at RM = (index + 0.5) * KilnLength / lengthOfArray
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'maxTemperatures': Array<number>;
    /**
     * An array of mean temperatures.  Each profile segment starts at RM = index * KilnLength / lengthOfArray  and ends at RM = (index + 1) * KilnLength / lengthOfArray  the center point is at RM = (index + 0.5) * KilnLength / lengthOfArray
     * @type {Array<number>}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'meanTemperatures': Array<number>;
    /**
     * The timestamp of this profile.  If this dto is a summary over a campaign or all campaigns the timestamp is null
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'timestamp'?: string | null;
    /**
     * The Campaign to which this profile belongs.  If campaign is null this is a summary over all profiles of a vessel
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto
     */
    'campaign'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsKilnStoppageDto
 */
export interface RHIMCementServiceV1ModelsKilnStoppageDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'beginTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'endTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsKilnStoppageDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsLinkedScanInformation
 */
export interface RHIMCementServiceV1ModelsLinkedScanInformation {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsLinkedScanInformation
     */
    'drillingMeasurementId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsLinkedScanInformation
     */
    'liningId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMCementServiceV1ModelsLinkedScanInformation
     */
    'hasValidKilnGeometry': boolean;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsProcessDataDto
 */
export interface RHIMCementServiceV1ModelsProcessDataDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsProcessDataDto
     */
    'timestamp': string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof RHIMCementServiceV1ModelsProcessDataDto
     */
    'processDataValues': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsPublishExtractioDto
 */
export interface RHIMCementServiceV1ModelsPublishExtractioDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsPublishExtractioDto
     */
    'extractionId'?: string | null;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsQualityDataDto
 */
export interface RHIMCementServiceV1ModelsQualityDataDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsQualityDataDto
     */
    'timestamp': string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof RHIMCementServiceV1ModelsQualityDataDto
     */
    'qualityDataValues': { [key: string]: { [key: string]: number; }; };
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsScanDetailedOverviewDto
 */
export interface RHIMCementServiceV1ModelsScanDetailedOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'scanDate': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'uploadDate': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'campaignId'?: number | null;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansScanState}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'scanState': RHIMCementServiceDataPointCloudScansScanState;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'originalFilename': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansPoint3D}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'referencePointOpening'?: RHIMCementServiceDataPointCloudScansPoint3D | null;
    /**
     * 
     * @type {RHIMCementServiceV1ModelsLinkedScanInformation}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'linkedScanInformation': RHIMCementServiceV1ModelsLinkedScanInformation;
    /**
     * 
     * @type {RHIMCementServiceV1ModelsExtractionOverviewDto}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'latestExtraction'?: RHIMCementServiceV1ModelsExtractionOverviewDto | null;
    /**
     * 
     * @type {RHIMCementServiceV1ModelsExtractionOverviewDto}
     * @memberof RHIMCementServiceV1ModelsScanDetailedOverviewDto
     */
    'publishedExtraction'?: RHIMCementServiceV1ModelsExtractionOverviewDto | null;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsScanDto
 */
export interface RHIMCementServiceV1ModelsScanDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'vesselId': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'scanDate': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'uploadDate': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'campaignId'?: number | null;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansScanState}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'scanState': RHIMCementServiceDataPointCloudScansScanState;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansPanoramaImagesState}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'panoramaImagesState': RHIMCementServiceDataPointCloudScansPanoramaImagesState;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansRefractoryCondition}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'refractoryCondition'?: RHIMCementServiceDataPointCloudScansRefractoryCondition | null;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansScanResolution}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'scanResolution'?: RHIMCementServiceDataPointCloudScansScanResolution | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'scanResponsible': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'originalFilename': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansPoint3D}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'referencePointOpening'?: RHIMCementServiceDataPointCloudScansPoint3D | null;
    /**
     * 
     * @type {Array<RHIMCementServiceV1ModelsExtractionOverviewDto>}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'extractions': Array<RHIMCementServiceV1ModelsExtractionOverviewDto>;
    /**
     * 
     * @type {RHIMCementServiceV1ModelsLinkedScanInformation}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'linkedScanInformation': RHIMCementServiceV1ModelsLinkedScanInformation;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsScanDto
     */
    'numberOfPanoramaImages': number;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsScanOverviewDto
 */
export interface RHIMCementServiceV1ModelsScanOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanOverviewDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanOverviewDto
     */
    'scanDate': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansRefractoryCondition}
     * @memberof RHIMCementServiceV1ModelsScanOverviewDto
     */
    'refractoryCondition'?: RHIMCementServiceDataPointCloudScansRefractoryCondition | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsScanOverviewDto
     */
    'campaignId'?: number | null;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsScanUpdateDto
 */
export interface RHIMCementServiceV1ModelsScanUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'vesselId': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'scanDate': string;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansRefractoryCondition}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'refractoryCondition'?: RHIMCementServiceDataPointCloudScansRefractoryCondition | null;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansScanResolution}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'scanResolution'?: RHIMCementServiceDataPointCloudScansScanResolution | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'scanResponsible': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {RHIMCementServiceDataPointCloudScansPoint3D}
     * @memberof RHIMCementServiceV1ModelsScanUpdateDto
     */
    'referencePointOpening'?: RHIMCementServiceDataPointCloudScansPoint3D | null;
}


/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsSliderRangeDto
 */
export interface RHIMCementServiceV1ModelsSliderRangeDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsSliderRangeDto
     */
    'initial': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsSliderRangeDto
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMCementServiceV1ModelsSliderRangeDto
     */
    'max': number;
}
/**
 * 
 * @export
 * @interface RHIMCementServiceV1ModelsTriggerExtractionDto
 */
export interface RHIMCementServiceV1ModelsTriggerExtractionDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMCementServiceV1ModelsTriggerExtractionDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {Array<RHIMCementServiceV1ModelsDustZoneDto>}
     * @memberof RHIMCementServiceV1ModelsTriggerExtractionDto
     */
    'dustZones'?: Array<RHIMCementServiceV1ModelsDustZoneDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF',
    Cou: 'COU'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];



/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a campaign
         * @summary Delete a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselsVesselidCampaignsCampaignnumber: async (vesselId: string, campaignNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('deleteVesselsVesselidCampaignsCampaignnumber', 'vesselId', vesselId)
            // verify required parameter 'campaignNumber' is not null or undefined
            assertParamExists('deleteVesselsVesselidCampaignsCampaignnumber', 'campaignNumber', campaignNumber)
            const localVarPath = `/vessels/{vesselId}/campaigns/{campaignNumber}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaignNumber"}}`, encodeURIComponent(String(campaignNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the configured campaigns for the specified vessel.
         * @summary Returns a list of the configured campaigns for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCampaigns: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidCampaigns', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/campaigns`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a campaign by campaign number
         * @summary Retrieves a campaign by campaign number
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCampaignsCampaignnumber: async (vesselId: string, campaignNumber: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidCampaignsCampaignnumber', 'vesselId', vesselId)
            // verify required parameter 'campaignNumber' is not null or undefined
            assertParamExists('getVesselsVesselidCampaignsCampaignnumber', 'campaignNumber', campaignNumber)
            const localVarPath = `/vessels/{vesselId}/campaigns/{campaignNumber}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaignNumber"}}`, encodeURIComponent(String(campaignNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new campaign
         * @summary Create a new campaign
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidCampaigns: async (vesselId: string, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('postVesselsVesselidCampaigns', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/campaigns`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCampaignsCampaignnumber: async (vesselId: string, campaignNumber: number, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('putVesselsVesselidCampaignsCampaignnumber', 'vesselId', vesselId)
            // verify required parameter 'campaignNumber' is not null or undefined
            assertParamExists('putVesselsVesselidCampaignsCampaignnumber', 'campaignNumber', campaignNumber)
            const localVarPath = `/vessels/{vesselId}/campaigns/{campaignNumber}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaignNumber"}}`, encodeURIComponent(String(campaignNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a campaign
         * @summary Delete a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the configured campaigns for the specified vessel.
         * @summary Returns a list of the configured campaigns for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsCampaignDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidCampaigns(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a campaign by campaign number
         * @summary Retrieves a campaign by campaign number
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsCampaignDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a new campaign
         * @summary Create a new campaign
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postVesselsVesselidCampaigns(vesselId: string, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsCampaignDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postVesselsVesselidCampaigns(vesselId, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsCampaignDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * Delete a campaign
         * @summary Delete a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the configured campaigns for the specified vessel.
         * @summary Returns a list of the configured campaigns for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCampaigns(vesselId: string, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsCampaignDto>> {
            return localVarFp.getVesselsVesselidCampaigns(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a campaign by campaign number
         * @summary Retrieves a campaign by campaign number
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: any): AxiosPromise<RHIMCementServiceV1ModelsCampaignDto> {
            return localVarFp.getVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new campaign
         * @summary Create a new campaign
         * @param {string} vesselId Format - uuid. Identifier of the vessel
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postVesselsVesselidCampaigns(vesselId: string, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: any): AxiosPromise<RHIMCementServiceV1ModelsCampaignDto> {
            return localVarFp.postVesselsVesselidCampaigns(vesselId, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} vesselId Format - uuid.
         * @param {number} campaignNumber Format - int32.
         * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: any): AxiosPromise<RHIMCementServiceV1ModelsCampaignDto> {
            return localVarFp.putVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * Delete a campaign
     * @summary Delete a campaign
     * @param {string} vesselId Format - uuid.
     * @param {number} campaignNumber Format - int32.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).deleteVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the configured campaigns for the specified vessel.
     * @summary Returns a list of the configured campaigns for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getVesselsVesselidCampaigns(vesselId: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getVesselsVesselidCampaigns(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a campaign by campaign number
     * @summary Retrieves a campaign by campaign number
     * @param {string} vesselId Format - uuid.
     * @param {number} campaignNumber Format - int32.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new campaign
     * @summary Create a new campaign
     * @param {string} vesselId Format - uuid. Identifier of the vessel
     * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public postVesselsVesselidCampaigns(vesselId: string, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).postVesselsVesselidCampaigns(vesselId, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a campaign
     * @summary Update a campaign
     * @param {string} vesselId Format - uuid.
     * @param {number} campaignNumber Format - int32.
     * @param {RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto} [rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public putVesselsVesselidCampaignsCampaignnumber(vesselId: string, campaignNumber: number, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto?: RHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).putVesselsVesselidCampaignsCampaignnumber(vesselId, campaignNumber, rHIMCementServiceV1ModelsCreateOrUpdateCampaignDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExtractionApi - axios parameter creator
 * @export
 */
export const ExtractionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the extraction with the given Id
         * @summary Deletes the extraction with the given Id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScansExtractionsExtractionid: async (extractionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extractionId' is not null or undefined
            assertParamExists('deleteScansExtractionsExtractionid', 'extractionId', extractionId)
            const localVarPath = `/scans/extractions/{extractionId}`
                .replace(`{${"extractionId"}}`, encodeURIComponent(String(extractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a scan extraction with the given id
         * @summary Returns a scan extraction with the given id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansExtractionsExtractionid: async (extractionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extractionId' is not null or undefined
            assertParamExists('getScansExtractionsExtractionid', 'extractionId', extractionId)
            const localVarPath = `/scans/extractions/{extractionId}`
                .replace(`{${"extractionId"}}`, encodeURIComponent(String(extractionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Triggers the start of a new extraction
         * @summary Triggers the start of a new extraction
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsTriggerExtractionDto} [rHIMCementServiceV1ModelsTriggerExtractionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScansScanidExtraction: async (scanId: string, rHIMCementServiceV1ModelsTriggerExtractionDto?: RHIMCementServiceV1ModelsTriggerExtractionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('postScansScanidExtraction', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}/extraction`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMCementServiceV1ModelsTriggerExtractionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtractionApi - functional programming interface
 * @export
 */
export const ExtractionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtractionApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the extraction with the given Id
         * @summary Deletes the extraction with the given Id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScansExtractionsExtractionid(extractionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScansExtractionsExtractionid(extractionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a scan extraction with the given id
         * @summary Returns a scan extraction with the given id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScansExtractionsExtractionid(extractionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsExtractionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScansExtractionsExtractionid(extractionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Triggers the start of a new extraction
         * @summary Triggers the start of a new extraction
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsTriggerExtractionDto} [rHIMCementServiceV1ModelsTriggerExtractionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScansScanidExtraction(scanId: string, rHIMCementServiceV1ModelsTriggerExtractionDto?: RHIMCementServiceV1ModelsTriggerExtractionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsExtractionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postScansScanidExtraction(scanId, rHIMCementServiceV1ModelsTriggerExtractionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtractionApi - factory interface
 * @export
 */
export const ExtractionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtractionApiFp(configuration)
    return {
        /**
         * Deletes the extraction with the given Id
         * @summary Deletes the extraction with the given Id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScansExtractionsExtractionid(extractionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScansExtractionsExtractionid(extractionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a scan extraction with the given id
         * @summary Returns a scan extraction with the given id
         * @param {string} extractionId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansExtractionsExtractionid(extractionId: string, options?: any): AxiosPromise<RHIMCementServiceV1ModelsExtractionDto> {
            return localVarFp.getScansExtractionsExtractionid(extractionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Triggers the start of a new extraction
         * @summary Triggers the start of a new extraction
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsTriggerExtractionDto} [rHIMCementServiceV1ModelsTriggerExtractionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScansScanidExtraction(scanId: string, rHIMCementServiceV1ModelsTriggerExtractionDto?: RHIMCementServiceV1ModelsTriggerExtractionDto, options?: any): AxiosPromise<RHIMCementServiceV1ModelsExtractionDto> {
            return localVarFp.postScansScanidExtraction(scanId, rHIMCementServiceV1ModelsTriggerExtractionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExtractionApi - object-oriented interface
 * @export
 * @class ExtractionApi
 * @extends {BaseAPI}
 */
export class ExtractionApi extends BaseAPI {
    /**
     * Deletes the extraction with the given Id
     * @summary Deletes the extraction with the given Id
     * @param {string} extractionId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtractionApi
     */
    public deleteScansExtractionsExtractionid(extractionId: string, options?: AxiosRequestConfig) {
        return ExtractionApiFp(this.configuration).deleteScansExtractionsExtractionid(extractionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a scan extraction with the given id
     * @summary Returns a scan extraction with the given id
     * @param {string} extractionId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtractionApi
     */
    public getScansExtractionsExtractionid(extractionId: string, options?: AxiosRequestConfig) {
        return ExtractionApiFp(this.configuration).getScansExtractionsExtractionid(extractionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Triggers the start of a new extraction
     * @summary Triggers the start of a new extraction
     * @param {string} scanId Format - uuid.
     * @param {RHIMCementServiceV1ModelsTriggerExtractionDto} [rHIMCementServiceV1ModelsTriggerExtractionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtractionApi
     */
    public postScansScanidExtraction(scanId: string, rHIMCementServiceV1ModelsTriggerExtractionDto?: RHIMCementServiceV1ModelsTriggerExtractionDto, options?: AxiosRequestConfig) {
        return ExtractionApiFp(this.configuration).postScansScanidExtraction(scanId, rHIMCementServiceV1ModelsTriggerExtractionDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KilnShellTemperatureProfilesApi - axios parameter creator
 * @export
 */
export const KilnShellTemperatureProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no campaign is given the aggregation is done across all data available for the vessel
         * @summary Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no camp
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaign] Format - int32.
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidKilnshelltemperatureprofiles: async (vesselId: string, campaign?: number, timestamp?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidKilnshelltemperatureprofiles', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/kilnshelltemperatureprofiles`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaign !== undefined) {
                localVarQueryParameter['campaign'] = campaign;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KilnShellTemperatureProfilesApi - functional programming interface
 * @export
 */
export const KilnShellTemperatureProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KilnShellTemperatureProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no campaign is given the aggregation is done across all data available for the vessel
         * @summary Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no camp
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaign] Format - int32.
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidKilnshelltemperatureprofiles(vesselId: string, campaign?: number, timestamp?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidKilnshelltemperatureprofiles(vesselId, campaign, timestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KilnShellTemperatureProfilesApi - factory interface
 * @export
 */
export const KilnShellTemperatureProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KilnShellTemperatureProfilesApiFp(configuration)
    return {
        /**
         * Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no campaign is given the aggregation is done across all data available for the vessel
         * @summary Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no camp
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaign] Format - int32.
         * @param {string} [timestamp] Format - date-time (as date-time in RFC3339).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidKilnshelltemperatureprofiles(vesselId: string, campaign?: number, timestamp?: string, options?: any): AxiosPromise<RHIMCementServiceV1ModelsKilnShellTemperatureProfileDto> {
            return localVarFp.getVesselsVesselidKilnshelltemperatureprofiles(vesselId, campaign, timestamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KilnShellTemperatureProfilesApi - object-oriented interface
 * @export
 * @class KilnShellTemperatureProfilesApi
 * @extends {BaseAPI}
 */
export class KilnShellTemperatureProfilesApi extends BaseAPI {
    /**
     * Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no campaign is given the aggregation is done across all data available for the vessel
     * @summary Returns aggregated profile data of the kiln shell temperature for a given vessel.  If a timestamp is given it returns the aggregated profile data from the start of the campaign until that timestamp  If a campaign is given the aggregation is done across the whole campaign  If no timestamp and no camp
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} [campaign] Format - int32.
     * @param {string} [timestamp] Format - date-time (as date-time in RFC3339).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KilnShellTemperatureProfilesApi
     */
    public getVesselsVesselidKilnshelltemperatureprofiles(vesselId: string, campaign?: number, timestamp?: string, options?: AxiosRequestConfig) {
        return KilnShellTemperatureProfilesApiFp(this.configuration).getVesselsVesselidKilnshelltemperatureprofiles(vesselId, campaign, timestamp, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KilnStoppagesApi - axios parameter creator
 * @export
 */
export const KilnStoppagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the stoppage events for the specified vessel.
         * @summary Returns a list of all the stoppage events for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidKilnstoppages: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidKilnstoppages', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/kilnstoppages`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KilnStoppagesApi - functional programming interface
 * @export
 */
export const KilnStoppagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KilnStoppagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the stoppage events for the specified vessel.
         * @summary Returns a list of all the stoppage events for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidKilnstoppages(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsKilnStoppageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidKilnstoppages(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KilnStoppagesApi - factory interface
 * @export
 */
export const KilnStoppagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KilnStoppagesApiFp(configuration)
    return {
        /**
         * Returns a list of all the stoppage events for the specified vessel.
         * @summary Returns a list of all the stoppage events for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidKilnstoppages(vesselId: string, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsKilnStoppageDto>> {
            return localVarFp.getVesselsVesselidKilnstoppages(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KilnStoppagesApi - object-oriented interface
 * @export
 * @class KilnStoppagesApi
 * @extends {BaseAPI}
 */
export class KilnStoppagesApi extends BaseAPI {
    /**
     * Returns a list of all the stoppage events for the specified vessel.
     * @summary Returns a list of all the stoppage events for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KilnStoppagesApi
     */
    public getVesselsVesselidKilnstoppages(vesselId: string, options?: AxiosRequestConfig) {
        return KilnStoppagesApiFp(this.configuration).getVesselsVesselidKilnstoppages(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PanoramaImagesApi - axios parameter creator
 * @export
 */
export const PanoramaImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @summary Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPanoramaImagesCoordinatesTxt: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScansScanidPanoramaImagesCoordinatesTxt', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}/panorama_images/coordinates.txt`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the panorama images with the given id
         * @summary Returns the panorama images with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} panoramaImageId Format - uuid.
         * @param {string} [quality] Optional query parameter that defines the quality of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPanoramaImagesImagesPanoramaimageid: async (scanId: string, panoramaImageId: string, quality?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScansScanidPanoramaImagesImagesPanoramaimageid', 'scanId', scanId)
            // verify required parameter 'panoramaImageId' is not null or undefined
            assertParamExists('getScansScanidPanoramaImagesImagesPanoramaimageid', 'panoramaImageId', panoramaImageId)
            const localVarPath = `/scans/{scanId}/panorama_images/images/{panoramaImageId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)))
                .replace(`{${"panoramaImageId"}}`, encodeURIComponent(String(panoramaImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PanoramaImagesApi - functional programming interface
 * @export
 */
export const PanoramaImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PanoramaImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @summary Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScansScanidPanoramaImagesCoordinatesTxt(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScansScanidPanoramaImagesCoordinatesTxt(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the panorama images with the given id
         * @summary Returns the panorama images with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} panoramaImageId Format - uuid.
         * @param {string} [quality] Optional query parameter that defines the quality of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScansScanidPanoramaImagesImagesPanoramaimageid(scanId: string, panoramaImageId: string, quality?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScansScanidPanoramaImagesImagesPanoramaimageid(scanId, panoramaImageId, quality, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PanoramaImagesApi - factory interface
 * @export
 */
export const PanoramaImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PanoramaImagesApiFp(configuration)
    return {
        /**
         * Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @summary Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPanoramaImagesCoordinatesTxt(scanId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getScansScanidPanoramaImagesCoordinatesTxt(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the panorama images with the given id
         * @summary Returns the panorama images with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} panoramaImageId Format - uuid.
         * @param {string} [quality] Optional query parameter that defines the quality of the image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPanoramaImagesImagesPanoramaimageid(scanId: string, panoramaImageId: string, quality?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getScansScanidPanoramaImagesImagesPanoramaimageid(scanId, panoramaImageId, quality, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PanoramaImagesApi - object-oriented interface
 * @export
 * @class PanoramaImagesApi
 * @extends {BaseAPI}
 */
export class PanoramaImagesApi extends BaseAPI {
    /**
     * Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
     * @summary Returns a csv file with the coordinates of the panorama images of the scan with the given id  The coordinates need to be in this weird csv format as we use potree (https://github.com/potree/potree) in the frontend and it requires this csv file
     * @param {string} scanId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanoramaImagesApi
     */
    public getScansScanidPanoramaImagesCoordinatesTxt(scanId: string, options?: AxiosRequestConfig) {
        return PanoramaImagesApiFp(this.configuration).getScansScanidPanoramaImagesCoordinatesTxt(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the panorama images with the given id
     * @summary Returns the panorama images with the given id
     * @param {string} scanId Format - uuid.
     * @param {string} panoramaImageId Format - uuid.
     * @param {string} [quality] Optional query parameter that defines the quality of the image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanoramaImagesApi
     */
    public getScansScanidPanoramaImagesImagesPanoramaimageid(scanId: string, panoramaImageId: string, quality?: string, options?: AxiosRequestConfig) {
        return PanoramaImagesApiFp(this.configuration).getScansScanidPanoramaImagesImagesPanoramaimageid(scanId, panoramaImageId, quality, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcessDataApi - axios parameter creator
 * @export
 */
export const ProcessDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the process data for the specified vessel.
         * @summary Returns a list of all the process data for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidProcessdata: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidProcessdata', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/processdata`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessDataApi - functional programming interface
 * @export
 */
export const ProcessDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the process data for the specified vessel.
         * @summary Returns a list of all the process data for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidProcessdata(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsProcessDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidProcessdata(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessDataApi - factory interface
 * @export
 */
export const ProcessDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessDataApiFp(configuration)
    return {
        /**
         * Returns a list of all the process data for the specified vessel.
         * @summary Returns a list of all the process data for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidProcessdata(vesselId: string, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsProcessDataDto>> {
            return localVarFp.getVesselsVesselidProcessdata(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcessDataApi - object-oriented interface
 * @export
 * @class ProcessDataApi
 * @extends {BaseAPI}
 */
export class ProcessDataApi extends BaseAPI {
    /**
     * Returns a list of all the process data for the specified vessel.
     * @summary Returns a list of all the process data for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDataApi
     */
    public getVesselsVesselidProcessdata(vesselId: string, options?: AxiosRequestConfig) {
        return ProcessDataApiFp(this.configuration).getVesselsVesselidProcessdata(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QualityDataApi - axios parameter creator
 * @export
 */
export const QualityDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of all the quality data for the specified vessel grouped by the process step
         * @summary Returns a list of all the quality data for the specified vessel grouped by the process step
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidQualitydata: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidQualitydata', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/qualitydata`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QualityDataApi - functional programming interface
 * @export
 */
export const QualityDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QualityDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of all the quality data for the specified vessel grouped by the process step
         * @summary Returns a list of all the quality data for the specified vessel grouped by the process step
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidQualitydata(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsQualityDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidQualitydata(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QualityDataApi - factory interface
 * @export
 */
export const QualityDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QualityDataApiFp(configuration)
    return {
        /**
         * Returns a list of all the quality data for the specified vessel grouped by the process step
         * @summary Returns a list of all the quality data for the specified vessel grouped by the process step
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidQualitydata(vesselId: string, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsQualityDataDto>> {
            return localVarFp.getVesselsVesselidQualitydata(vesselId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QualityDataApi - object-oriented interface
 * @export
 * @class QualityDataApi
 * @extends {BaseAPI}
 */
export class QualityDataApi extends BaseAPI {
    /**
     * Returns a list of all the quality data for the specified vessel grouped by the process step
     * @summary Returns a list of all the quality data for the specified vessel grouped by the process step
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QualityDataApi
     */
    public getVesselsVesselidQualitydata(vesselId: string, options?: AxiosRequestConfig) {
        return QualityDataApiFp(this.configuration).getVesselsVesselidQualitydata(vesselId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScanApi - axios parameter creator
 * @export
 */
export const ScanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the scan with the given Id
         * @summary Deletes the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScansScanid: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('deleteScansScanid', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a scan with the given id
         * @summary Returns a scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanid: async (scanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScansScanid', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the  file with the given name of the potree folder of the scan with the given id
         * @summary Returns the  file with the given name of the potree folder of the scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPotreeFilename: async (scanId: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('getScansScanidPotreeFilename', 'scanId', scanId)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getScansScanidPotreeFilename', 'filename', filename)
            const localVarPath = `/scans/{scanId}/potree/{filename}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of the scans for the specified vessel.
         * @summary Returns a list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaignNumber] Format - int32. Optional query parameter to filter the scans by campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidScans: async (vesselId: string, campaignNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidScans', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/scans`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignNumber !== undefined) {
                localVarQueryParameter['campaignNumber'] = campaignNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a more detailed list of the scans for the specified vessel.
         * @summary Returns a more detailed list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidScansDetailed: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVesselsVesselidScansDetailed', 'vesselId', vesselId)
            const localVarPath = `/vessels/{vesselId}/scans/detailed`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unpublish all extractions and then published one extraction (if any is provided)
         * @summary Unpublish all extractions and then published one extraction (if any is provided)
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsPublishExtractioDto} [rHIMCementServiceV1ModelsPublishExtractioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScansScanidPublish: async (scanId: string, rHIMCementServiceV1ModelsPublishExtractioDto?: RHIMCementServiceV1ModelsPublishExtractioDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('postScansScanidPublish', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}/publish`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMCementServiceV1ModelsPublishExtractioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the scan with the given Id
         * @summary Updates the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsScanUpdateDto} [rHIMCementServiceV1ModelsScanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScansScanid: async (scanId: string, rHIMCementServiceV1ModelsScanUpdateDto?: RHIMCementServiceV1ModelsScanUpdateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scanId' is not null or undefined
            assertParamExists('putScansScanid', 'scanId', scanId)
            const localVarPath = `/scans/{scanId}`
                .replace(`{${"scanId"}}`, encodeURIComponent(String(scanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rHIMCementServiceV1ModelsScanUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScanApi - functional programming interface
 * @export
 */
export const ScanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScanApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the scan with the given Id
         * @summary Deletes the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScansScanid(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScansScanid(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a scan with the given id
         * @summary Returns a scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScansScanid(scanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMCementServiceV1ModelsScanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScansScanid(scanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the  file with the given name of the potree folder of the scan with the given id
         * @summary Returns the  file with the given name of the potree folder of the scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScansScanidPotreeFilename(scanId: string, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScansScanidPotreeFilename(scanId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of the scans for the specified vessel.
         * @summary Returns a list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaignNumber] Format - int32. Optional query parameter to filter the scans by campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidScans(vesselId: string, campaignNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsScanOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidScans(vesselId, campaignNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a more detailed list of the scans for the specified vessel.
         * @summary Returns a more detailed list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVesselsVesselidScansDetailed(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMCementServiceV1ModelsScanDetailedOverviewDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselsVesselidScansDetailed(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unpublish all extractions and then published one extraction (if any is provided)
         * @summary Unpublish all extractions and then published one extraction (if any is provided)
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsPublishExtractioDto} [rHIMCementServiceV1ModelsPublishExtractioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postScansScanidPublish(scanId: string, rHIMCementServiceV1ModelsPublishExtractioDto?: RHIMCementServiceV1ModelsPublishExtractioDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postScansScanidPublish(scanId, rHIMCementServiceV1ModelsPublishExtractioDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the scan with the given Id
         * @summary Updates the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsScanUpdateDto} [rHIMCementServiceV1ModelsScanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putScansScanid(scanId: string, rHIMCementServiceV1ModelsScanUpdateDto?: RHIMCementServiceV1ModelsScanUpdateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putScansScanid(scanId, rHIMCementServiceV1ModelsScanUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScanApi - factory interface
 * @export
 */
export const ScanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScanApiFp(configuration)
    return {
        /**
         * Deletes the scan with the given Id
         * @summary Deletes the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScansScanid(scanId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteScansScanid(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a scan with the given id
         * @summary Returns a scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanid(scanId: string, options?: any): AxiosPromise<RHIMCementServiceV1ModelsScanDto> {
            return localVarFp.getScansScanid(scanId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the  file with the given name of the potree folder of the scan with the given id
         * @summary Returns the  file with the given name of the potree folder of the scan with the given id
         * @param {string} scanId Format - uuid.
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScansScanidPotreeFilename(scanId: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.getScansScanidPotreeFilename(scanId, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of the scans for the specified vessel.
         * @summary Returns a list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} [campaignNumber] Format - int32. Optional query parameter to filter the scans by campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidScans(vesselId: string, campaignNumber?: number, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsScanOverviewDto>> {
            return localVarFp.getVesselsVesselidScans(vesselId, campaignNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a more detailed list of the scans for the specified vessel.
         * @summary Returns a more detailed list of the scans for the specified vessel.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVesselsVesselidScansDetailed(vesselId: string, options?: any): AxiosPromise<Array<RHIMCementServiceV1ModelsScanDetailedOverviewDto>> {
            return localVarFp.getVesselsVesselidScansDetailed(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unpublish all extractions and then published one extraction (if any is provided)
         * @summary Unpublish all extractions and then published one extraction (if any is provided)
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsPublishExtractioDto} [rHIMCementServiceV1ModelsPublishExtractioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postScansScanidPublish(scanId: string, rHIMCementServiceV1ModelsPublishExtractioDto?: RHIMCementServiceV1ModelsPublishExtractioDto, options?: any): AxiosPromise<void> {
            return localVarFp.postScansScanidPublish(scanId, rHIMCementServiceV1ModelsPublishExtractioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the scan with the given Id
         * @summary Updates the scan with the given Id
         * @param {string} scanId Format - uuid.
         * @param {RHIMCementServiceV1ModelsScanUpdateDto} [rHIMCementServiceV1ModelsScanUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScansScanid(scanId: string, rHIMCementServiceV1ModelsScanUpdateDto?: RHIMCementServiceV1ModelsScanUpdateDto, options?: any): AxiosPromise<void> {
            return localVarFp.putScansScanid(scanId, rHIMCementServiceV1ModelsScanUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScanApi - object-oriented interface
 * @export
 * @class ScanApi
 * @extends {BaseAPI}
 */
export class ScanApi extends BaseAPI {
    /**
     * Deletes the scan with the given Id
     * @summary Deletes the scan with the given Id
     * @param {string} scanId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public deleteScansScanid(scanId: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).deleteScansScanid(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a scan with the given id
     * @summary Returns a scan with the given id
     * @param {string} scanId Format - uuid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public getScansScanid(scanId: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).getScansScanid(scanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the  file with the given name of the potree folder of the scan with the given id
     * @summary Returns the  file with the given name of the potree folder of the scan with the given id
     * @param {string} scanId Format - uuid.
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public getScansScanidPotreeFilename(scanId: string, filename: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).getScansScanidPotreeFilename(scanId, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of the scans for the specified vessel.
     * @summary Returns a list of the scans for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} [campaignNumber] Format - int32. Optional query parameter to filter the scans by campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public getVesselsVesselidScans(vesselId: string, campaignNumber?: number, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).getVesselsVesselidScans(vesselId, campaignNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a more detailed list of the scans for the specified vessel.
     * @summary Returns a more detailed list of the scans for the specified vessel.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public getVesselsVesselidScansDetailed(vesselId: string, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).getVesselsVesselidScansDetailed(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unpublish all extractions and then published one extraction (if any is provided)
     * @summary Unpublish all extractions and then published one extraction (if any is provided)
     * @param {string} scanId Format - uuid.
     * @param {RHIMCementServiceV1ModelsPublishExtractioDto} [rHIMCementServiceV1ModelsPublishExtractioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public postScansScanidPublish(scanId: string, rHIMCementServiceV1ModelsPublishExtractioDto?: RHIMCementServiceV1ModelsPublishExtractioDto, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).postScansScanidPublish(scanId, rHIMCementServiceV1ModelsPublishExtractioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the scan with the given Id
     * @summary Updates the scan with the given Id
     * @param {string} scanId Format - uuid.
     * @param {RHIMCementServiceV1ModelsScanUpdateDto} [rHIMCementServiceV1ModelsScanUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScanApi
     */
    public putScansScanid(scanId: string, rHIMCementServiceV1ModelsScanUpdateDto?: RHIMCementServiceV1ModelsScanUpdateDto, options?: AxiosRequestConfig) {
        return ScanApiFp(this.configuration).putScansScanid(scanId, rHIMCementServiceV1ModelsScanUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



