import { useMemoCompare } from '@rhim/react';
import { hasElements, head, isDefined } from '@rhim/utils';
import { join } from 'lodash';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import useSessionStorageState from 'use-session-storage-state';

import { LOCAL_STORAGE_PREFIX_APO } from '../utilities/storage';

type PersistedValue = UUID | null;

function useSelectedVesselId(initialState: PersistedValue) {
  return useSessionStorageState(join([LOCAL_STORAGE_PREFIX_APO, 'comparedVesselId'], ''), {
    defaultValue: initialState,
  });
}

function usePersistedVessel(vessels: APO.VesselV2[], initialValue?: PersistedValue): [PersistedValue, React.Dispatch<React.SetStateAction<PersistedValue>>] {
  const [persistedVesselId, setPersistedVesselId] = useSelectedVesselId(initialValue ?? null);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getNextCorrectValue = React.useCallback((persistedVesselId: PersistedValue, vessels: APO.VesselV2[]): PersistedValue => {
    if (!hasElements(vessels)) {
      return null;
    } else {
      const persistedVessel = vessels.find((vessel) => vessel.id === persistedVesselId);
      const isPersistedVesselIdValid = isDefined(persistedVessel);

      if (isPersistedVesselIdValid) {
        return persistedVesselId;
      } else {
        const firstAvailableVessel = head(vessels);

        return firstAvailableVessel.id;
      }
    }
  }, []);

  const currentValue = React.useMemo(() => {
    return getNextCorrectValue(persistedVesselId, vessels);
  }, [getNextCorrectValue, persistedVesselId, vessels]);

  React.useDebugValue(`Currently selected vessel: ${currentValue}`);

  React.useEffect(() => {
    const nextValue = getNextCorrectValue(persistedVesselId, vessels);

    if (nextValue !== persistedVesselId) {
      setPersistedVesselId(nextValue);
    }
  }, [persistedVesselId, setPersistedVesselId, getNextCorrectValue, vessels]);

  const memoizedValue: [PersistedValue, React.Dispatch<React.SetStateAction<PersistedValue>>] = useMemoCompare(
    [currentValue, setPersistedVesselId],
    ([previousId], [nextId]) => {
      return previousId === nextId;
    }
  );

  return memoizedValue;
}

interface Props {
  selectedComparedVesselId: UUID | null;
  setSelectedComparedVesselId: React.Dispatch<React.SetStateAction<UUID | null>>; //Used for persisting the vessel ID into local storage.
}
const VESSEL_ID_PARAM = 'vid';

export function usePersistedComparedVessel(vessels: APO.VesselV2[]): Props {
  const location = useLocation();

  // eslint-disable-next-line compat/compat
  const query = new URLSearchParams(location.search);
  const queryVesselId = query.get(VESSEL_ID_PARAM);
  // eslint-disable-next-line compat/compat
  const [persistedVesselId, setSelectedVesselId] = usePersistedVessel(vessels, queryVesselId as UUID | null);
  /**
  /**
   * Returns the selected vessel ID.
   */
  const selectedVesselId = React.useMemo((): UUID | null => {
    const persistedVessel = vessels.find((vessel) => vessel.id === persistedVesselId);
    const queryVessel = vessels.find((vessel) => vessel.id === queryVesselId);
    if (isDefined(vessels)) {
      if (isDefined(persistedVessel)) {
        if (isDefined(queryVessel) && persistedVessel.vesselType !== queryVessel.vesselType) {
          return queryVessel.id;
        }
        return persistedVesselId;
      }
      if (hasElements(vessels)) {
        return head(vessels).id;
      }
    }
    return null;
  }, [queryVesselId, persistedVesselId, vessels]);

  React.useEffect(() => {
    if (isDefined(queryVesselId)) {
      const persistedVessel = vessels.find((vessel) => vessel.id === persistedVesselId);
      const queryVessel = vessels.find((vessel) => vessel.id === queryVesselId);

      if (isDefined(persistedVessel) && isDefined(queryVessel)) {
        if (persistedVessel.vesselType !== queryVessel.vesselType) {
          setSelectedVesselId(queryVessel.id);
        }
      }
    }
  }, [setSelectedVesselId, queryVesselId, vessels, persistedVesselId]);
  const memoizedValue: UUID | null = useMemoCompare(selectedVesselId, (previousId, nextId) => {
    return previousId === nextId;
  });

  return { selectedComparedVesselId: memoizedValue, setSelectedComparedVesselId: setSelectedVesselId };
}
